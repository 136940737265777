export const adminRoutes: TNavigationRoute[] = [
    {
        name: "Панель разработчика",
        key: "admin",
        route: "admin",
        type: "route",
        parent_key: null,
        subroutes: [
            {
                name: "Блоки для шаблона",
                type: "route",
                key: "/actions",
                route: "/actions",
                parent_key: "admin",
            },

            {
                type: "route",
                name: "Переменные",
                key: "/variables",
                route: "/variables",
                parent_key: "admin",
            },
            {
                type: "route",
                name: "Вычисляемые значения",
                parent_key: "admin",

                key: "/calculated-values",
                route: "/calculated-values",
            },
            {
                type: "route",
                name: "Типы данных полей",
                key: "/field-datatypes",
                route: "/field-datatypes",
                parent_key: "admin",
            },

            {
                type: "route",
                name: "Сканер QR",
                parent_key: "admin",

                key: "/qr-scanner",
                route: "/qr-scanner",
            },
            {
                type: "route",
                name: "Настройки портала",
                parent_key: "admin",
                key: "/portal-settings",
                route: "/portal-settings",
            },
            {
                type: "route",
                name: "Настройки прав",
                parent_key: "admin",
                key: "/capabilities-editor",
                route: "/capabilities-editor",
            },
        ],
    },
    // {
    //     name: "Таблица сущностей",
    //     key: "tables",
    //     type: "route",
    //     route: "tables",
    //     parent_key: null,
    //     subroutes: [
    //         {
    //             type: "route",
    //             name: "Пользователь",
    //             key: "/entity-table-user",
    //             route: "/entity-table/1",
    //             parent_key: "tables",
    //         },
    //     ],
    // },
];
// export const menuItems: SideBarItems[] = [
// 	{
// 		label: 'Профиль',
// 		key: 'user',
// 		className: 'sidebar__menu-item sidebar__text',
// 	},
// 	{
// 		label: 'Дашбоард',
// 		key: '/dashboard',
// 		className: 'sidebar__menu-item sidebar__text',
// 	},
// 	{
// 		label: 'Бизнес-процессы',
// 		key: '/biz-procs',
// 		className: 'sidebar__menu-item sidebar__text',
// 	},

// 	{
// 		label: 'Согласование',
// 		key: '/agreement',
// 		className: 'sidebar__menu-item sidebar__text',
// 	},
// 	{
// 		label: 'Ожидаю согласование',
// 		key: '/my-agreement',
// 		className: 'sidebar__menu-item sidebar__text',
// 	},
// 	{
// 		label: 'Мои формы',
// 		key: '/my-forms',
// 		className: 'sidebar__menu-item sidebar__text',
// 	},
// ];
