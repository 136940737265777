import { apiUrl } from "@shared/api/api";
import { PaginatedSelect } from "@shared/ui/PaginatedSelect";

interface ISelectEntityRow<T> {
    multiple?: boolean;
    onlyRead?: boolean;
    placeholder?: string;
    labelKey?: string;
    value: T | null;
    entityTableName: string;
    onChange: (value: T) => void;
}

const SelectEntityRow = <T,>({
    multiple,
    onlyRead,
    placeholder,
    value,
    labelKey,
    entityTableName,
    onChange,
}: ISelectEntityRow<T>) => {
    return (
        <PaginatedSelect
            onlyRead={onlyRead}
            multiple={multiple}
            requestUrl={`${apiUrl}/api/items/${entityTableName}`}
            value={value}
            placeholder={placeholder}
            valueKey="id"
            labelKey={labelKey ?? "name"}
            onChangeValue={onChange}
            initialOptions={[]}
        />
    );
};

export default SelectEntityRow;
