import { ActionListItem } from "@entities/Action";
import React from "react";
import { useActionListItem } from "../model";
interface IActionCreatedItem {
    action: TAction;
}
const ActionCreatedItem = ({ action }: IActionCreatedItem) => {
    const { onCreate, onDelete, deleteLoading, error } = useActionListItem(action);

    return (
        <div>
            <ActionListItem
                error={error}
                deleteLoading={deleteLoading}
                onSave={onCreate}
                onCloseCustom={onDelete}
                onDelete={onDelete}
                isEditing={true}
                action={action}
            />
        </div>
    );
};

export default ActionCreatedItem;
