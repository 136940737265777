import { BizProcTimelineItemDot } from "@entities/BizProc";
import { Button, Popconfirm } from "antd";
import "./BizProcHistory.scss";
import { useBizProcHistoryActions } from "../model";
import { BizProcItemEditModal } from "@features/BizProcTemplate/BizProcTemplateEditor";

interface IBizProcHistoryActions {
    action: TBizProcAction;
}

const BizProcHistoryActions = ({ action }: IBizProcHistoryActions) => {
    const {
        userIsAdmin,
        isEditing,
        templateItem,
        loading,
        submitLoading,
        setIsEditing,
        openGoTemplateModal,
        onSubmit,
    } = useBizProcHistoryActions(action);
    return (
        <>
            <Popconfirm
                title="Перейти к этому действию?"
                okText="Перейти"
                okButtonProps={{
                    loading: loading,
                }}
                disabled={!userIsAdmin}
                onConfirm={openGoTemplateModal}
                cancelText="Отмена"
            >
                {userIsAdmin ? (
                    <Button
                        disabled={!userIsAdmin}
                        type="link"
                        icon={<BizProcTimelineItemDot action={action.action} />}
                    />
                ) : (
                    <BizProcTimelineItemDot action={action.action} />
                )}
            </Popconfirm>
            {templateItem && (
                <BizProcItemEditModal
                    disabled={false}
                    isOpen={isEditing}
                    submitLoading={submitLoading}
                    okText={"Сохранить и перейти"}
                    setIsOpen={setIsEditing}
                    templateItem={templateItem}
                    bizProcTemplate={templateItem.biz_proc_template!}
                    onSubmit={onSubmit}
                />
            )}
        </>
    );
};

export default BizProcHistoryActions;
