import { FormInputItem, labeledDatatypes } from "@entities/Datatype";
import "./EntityCardItem.scss";

interface IEntityCardItem {
    field: TEntityField;
    entityRowOrId: any;
    onClick?: () => void;
    withLabel?: boolean;
    isTitle?: boolean;
    isDescription?: boolean;
}

const EntityCardItem = ({
    field,
    withLabel,
    entityRowOrId,
    isTitle,
    isDescription,
}: IEntityCardItem) => {
    const label =
        withLabel || labeledDatatypes.includes(field.datatype) ? field.name : undefined;
    const textStyle = isTitle
        ? {}
        : isDescription
          ? {
                fontSize: "11px !important",
                color: "var(--secondary-text)",
            }
          : undefined;

    return (
        <div
            style={{
                height: field.datatype === "file" ? "fit-content" : "fit-content",
            }}
            className="entity-list-card-field"
        >
            {label && (
                <div
                    className={`entity-list-card-field-column ${isTitle ? "link-style" : ""}`}
                    style={textStyle}
                >
                    {label}:
                </div>
            )}
            <div
                className={`entity-list-card-field-value ${isTitle ? "link-style" : ""}`}
                style={{
                    width: label ? "50%" : "100%",
                    ...(textStyle ?? {}),
                }}
                onClick={
                    field.datatype === "entityList" ||
                    field.datatype === "entity" ||
                    field.datatype === "file"
                        ? (e) => {
                              e.stopPropagation();
                          }
                        : undefined
                }
            >
                <FormInputItem
                    field={field}
                    readValueTextStyle={textStyle}
                    inputId={"-"}
                    additionalParameters={{
                        ...(field.additional_parameters ?? {}),
                        fileSmallFormat: true,
                    }}
                    datatype={field.datatype as unknown as string}
                    value={entityRowOrId?.[field.key]}
                    onlyRead={true}
                    onChange={() => {}}
                />
            </div>
        </div>
    );
};

export default EntityCardItem;
