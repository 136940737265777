import { navigationRouteModel } from "@entities/NavigationRoute";
import { MenuProps } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useNavigate } from "react-router-dom";
import { adminRoutes } from "../sideBarItems";
import { useSelector } from "react-redux";
import { SidebarItem } from "../ui/SidebarItem";
import { userModel } from "@entities/User";
import { bizProcModel } from "@entities/BizProc";
import { portalSettingModel } from "@entities/PortalSetting";

export const useSidebar = (collapsed: boolean) => {
    const { navigationRoutes } = navigationRouteModel.useLoadNavigationRoutes();
    const logo = useSelector(portalSettingModel.selectPortalSettingsPrepared)?.logoPortal;

    const navigate = useNavigate();
    const userIsAdmin = useSelector(userModel.selectUserIsAdmin);
    const editingMode = useSelector(navigationRouteModel.selectIsInEditingMode);
    const agreementsCount = useSelector(bizProcModel.selectAgreementsCounter);
    const formsCount = useSelector(bizProcModel.selectFormsCounter);
    const badges: any = {
        "/dashboard": formsCount + agreementsCount,
        agreement: agreementsCount,
        "my-forms": formsCount,
        dashboard: formsCount + agreementsCount,
    };

    const getMenuItem = (
        navigationRoute: TNavigationRoute
    ): ItemType & {
        children: any;
        navigationRoute: any;
    } => {
        return {
            className: "sidebar__menu-item sidebar__text",
            label: SidebarItem({
                collapsed,
                navigationRoute,
                badge: badges?.[navigationRoute.route],
            }),
            key: navigationRoute.key,
            navigationRoute: navigationRoute,
            onClick: () => {
                if (
                    navigationRoute.subroutes?.length === 0 ||
                    !navigationRoute.subroutes
                ) {
                    if (navigationRoute.type === "route" && !navigationRoute.blank) {
                        navigate(navigationRoute.route);
                    } else {
                        window.open(
                            navigationRoute.route,
                            navigationRoute.blank ? "_blank" : undefined
                        );
                    }
                }
            },

            children:
                navigationRoute.subroutes?.length === 0
                    ? undefined
                    : navigationRoute.subroutes?.map((innerRoute: TNavigationRoute) => {
                          return getMenuItem(innerRoute);
                      }),
        };
    };

    const menuItems: MenuProps["items"] = [
        ...navigationRoutes,
        ...(userIsAdmin ? adminRoutes : []),
    ].map((navigationRoute) => {
        return getMenuItem(navigationRoute);
    });
    return { badges, logo, menuItems, editingMode };
};
