import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { viewPatternModel } from "@entities/ViewPattern";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

interface IViewPatternActions {
    viewPattern: TViewPattern;
}

const ViewPatternActions = ({ viewPattern }: IViewPatternActions) => {
    const dispatch = useDispatch<AppDispatch>();
    const deleteLoading = useSelector((state) =>
        viewPatternModel.selectDeleteLoading(state, viewPattern.id)
    );
    const onClickEdit = (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        viewPattern: TViewPattern
    ) => {
        event.stopPropagation();
        dispatch(viewPatternModel.setEditingViewPattern(viewPattern));
    };
    const onClickDelete = (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        viewPattern: TViewPattern
    ) => {
        event.stopPropagation();
        dispatch(viewPatternModel.deleteViewPatternThunk(viewPattern.id));
    };

    return (
        <div style={{ display: "flex" }}>
            <Button
                style={{ padding: "0 !important", margin: "0 !important" }}
                icon={<EditOutlined />}
                type="link"
                onClick={(e) => onClickEdit(e, viewPattern)}
            />
            <Button
                loading={deleteLoading}
                type="link"
                danger
                style={{ padding: "0 !important", margin: "0 !important" }}
                icon={<DeleteOutlined />}
                onClick={(e) => onClickDelete(e, viewPattern)}
            />
        </div>
    );
};

export default ViewPatternActions;
