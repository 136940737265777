import { datatypeModel } from "@entities/Datatype";
import DatatypeListItem from "./DatatypeListItem";
import "./DatatypesList.scss";

const DatatypesList = () => {
    const { datatypes } = datatypeModel.useLoadDatatypes();
    return (
        <div className="datatypes-list">
            {datatypes.map((datatype) => {
                return (
                    <div key={datatype.id} className="datatypes-list__item">
                        <DatatypeListItem datatype={datatype} />
                    </div>
                );
            })}
        </div>
    );
};

export default DatatypesList;
