import { estimationLib } from "@entities/Estimations";
import "./EstimationValuesList.scss";
import EstimationValuesListItem from "./EstimationValuesListItem";
import EstimationValuesListItemWithOptions from "./EstimationValuesListItemWithOptions";

const EstimationValuesList = ({
    entityId,
    templateValues,
}: {
    entityId: number;
    templateValues: TEstimationValues;
}) => {
    return (
        <>
            {templateValues.map((templateValue, index) => {
                if (
                    estimationLib.isEstimationValueTypeWithOptions(templateValue.datatype)
                )
                    return (
                        <EstimationValuesListItemWithOptions
                            templateValue={templateValue}
                            entityId={entityId}
                        />
                    );
                return (
                    <EstimationValuesListItem
                        key={index}
                        value={templateValue.value}
                        name={templateValue.name}
                    />
                );
            })}
        </>
    );
};

export default EstimationValuesList;
