import {
    entityRowModel,
    getEntityRowsByEntityFieldWithAdditionalColumns,
    getParametersForRequest,
    getPreparedEntityRowsByEntityFieldAfterPagination,
} from "@entities/EntityRow";
import { getEntityRowsByEntityField } from "@entities/EntityRow/api";
import { fieldOptionApi } from "@entities/FieldOption";
import { stageApi } from "@entities/Stage";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIdForPaginationRequest } from "../lib";

export const useLoadEntityRowsKanban = (
    entityId: number,
    entityFieldId: number | null
) => {
    const [isPaginationLoading, setIsPaginationLoading] = useState<{
        [columnValue: string]: boolean;
    }>({});
    const [entityRowsByEntityField, setEntityRowsByEntityField] =
        useState<TEntityRowsByEntityField>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const filters = useSelector(entityRowModel.selectFilters);
    const textFilterValue = useSelector(entityRowModel.selectTextFilterValue);
    const currentCategory = useSelector(entityRowModel.selectCurrentCategory);

    const entityFields = useSelector(entityRowModel.selectEntityFields);
    const entityRows = useSelector(entityRowModel.selectEntityRows);
    const sendTimeout = useRef<any>();

    useEffect(() => {
        return () => {
            dispatch(entityRowModel.setEntityFields([]));
        };
    }, []);

    useEffect(() => {
        setEntityRowsByEntityField({});
        if (entityFieldId) {
            loadEntityRowsKanban(filters, {}, textFilterValue);
        }
    }, [filters, entityFieldId, currentCategory, textFilterValue]);

    const loadEntityRowsKanban = (
        filters: TFilterValue[],
        paginationConfig: TPaginationConfig,
        textFilterValue: string | undefined
    ) => {
        setIsLoading(true);
        const parameters = getParametersForRequest(filters);
        if (sendTimeout.current) clearTimeout(sendTimeout.current);

        sendTimeout.current = setTimeout(() => {
            getEntityRowsByEntityField(
                entityId,
                entityFieldId!,
                parameters,
                paginationConfig,
                textFilterValue
            )
                .then((response: any) => {
                    const responsedEntityRowsByEntityField = response.data.data.data;

                    dispatch(
                        entityRowModel.setEntityFields(response.data.data.entity_fields)
                    );
                    const foundedEntityFieldId = response.data.data.entity_fields.find(
                        (entityField: TEntityField) => entityField.id === entityFieldId
                    );
                    if (foundedEntityFieldId!.datatype === "list") {
                        fieldOptionApi
                            .getFieldOptions(entityFieldId!, "entity-field")
                            .then((response) => {
                                setEntityRowsByEntityField(
                                    getEntityRowsByEntityFieldWithAdditionalColumns(
                                        responsedEntityRowsByEntityField,
                                        foundedEntityFieldId!,
                                        response.data.data
                                    )
                                );
                            });
                    }
                    if (foundedEntityFieldId!.datatype === "stage") {
                        stageApi.getStages(entityId, currentCategory).then((response) => {
                            setEntityRowsByEntityField(
                                getEntityRowsByEntityFieldWithAdditionalColumns(
                                    responsedEntityRowsByEntityField,
                                    foundedEntityFieldId!,

                                    response.data.data.map((stage) => {
                                        return {
                                            ...stage,
                                            label: stage.label,
                                            value: stage.id,
                                            background_color: stage.background_color,
                                        };
                                    })
                                )
                            );
                        });
                    } else {
                        setEntityRowsByEntityField(responsedEntityRowsByEntityField);
                    }
                    dispatch(
                        entityRowModel.setEntityUserCapabilities(
                            response.data.data.capabilities
                        )
                    );
                })
                .catch((e: any) => {
                    notificationEmit({
                        type: "error",
                        title: "Ошибка",
                        description: "Не удалость загрузить значения полей сущности",
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }, 500);
    };

    const loadEntityRowsByPagination = (columnValue: any) => {
        setIsPaginationLoading({ [columnValue ?? ""]: true });
        const idForPagination = getIdForPaginationRequest(
            entityRowsByEntityField[columnValue ?? ""]
        );
        const paginationConfig = { [columnValue ?? ""]: idForPagination };
        const parameters = getParametersForRequest(filters);
        getEntityRowsByEntityField(entityId, entityFieldId!, parameters, paginationConfig)
            .then((response: any) => {
                setEntityRowsByEntityField(
                    getPreparedEntityRowsByEntityFieldAfterPagination(
                        entityRowsByEntityField,
                        columnValue ?? "",
                        response.data.data.data
                    )
                );
            })
            .catch((e: any) => {
                notificationEmit({
                    type: "error",
                    title: "Ошибка",
                    description: "Не удалость загрузить значения полей сущности",
                });
            })
            .finally(() => {
                setIsPaginationLoading({ [columnValue ?? ""]: false });
            });
    };

    return {
        isPaginationLoading,
        isLoading,
        entityFields,
        entityRowsByEntityField,
        entityRows,
        setEntityRowsByEntityField,
        loadEntityRowsByPagination,
    };
};
