// import { ArcherElement } from "react-archer";
import "./BizProcTemplate.scss";
import {
    getArcherRelations,
    getParentNextRelation,
    getSingleDefaultRelationRule,
} from "../lib";
import {
    BizProcActionItem,
    bizProcLib,
    bizProcTemplateModel,
} from "@entities/BizProcTemplate";
import { useBizProcUpdateAction } from "../model/UseBizProcUpdateAction";

import { BizProcItemEditModal } from "../BizProcItemEditModal";
import ArcherElement from "./ArcherElement";
import { BizProcActionAddButton, withFixedAction } from "../BizProcActionAddButton";
import { useSelector } from "react-redux";

interface IBizProcTemplateItem {
    templateItem: TBizProcTemplateAction;
    customEndItemKey?: string;
    index: number;
    isParentWithRowChild?: boolean;
    onChangeTemplateItem: (templateItem: TBizProcTemplateAction) => void;
    onAddTemplateItem: (
        templateItem: TBizProcTemplateAction,
        newTemplateItem: TBizProcTemplateAction
    ) => void;
    onDeleteTemplateItem: (deletedTemplateItem: TBizProcTemplateAction) => void;
    templateGroupItems: TBizProcTemplateAction[];
    parentItem: TBizProcTemplateAction;
    parentParentItem?: TBizProcTemplateAction;
    disabled?: boolean;
    parentNextItem: TBizProcTemplateAction | undefined;
}

const BizProcTemplateItem = ({
    templateItem,
    isParentWithRowChild,
    index,
    parentParentItem,
    disabled,
    parentItem,
    customEndItemKey,
    templateGroupItems,
    parentNextItem,
    onAddTemplateItem,
    onDeleteTemplateItem,
    onChangeTemplateItem,
}: IBizProcTemplateItem) => {
    const bizProcTemplate = useSelector(
        bizProcTemplateModel.selectEditingBizProcTemplateItem
    );
    const isBizProcWithRowChild = bizProcLib.isBizProcWithRowChild(templateItem.action);
    const isAllowSelfChild = bizProcLib.isBizProcWithSelfChilds(templateItem.action);
    const isBizProcNotAllowAddAfter = bizProcLib.isBizProcNotAllowAddAfter(
        templateItem.action
    );

    const {
        addTemplateItemAfter,
        editAction,
        onAddTemplateItemParent,
        onDeleteTemplateItem_,
        onDeleteTemplateItemParent,
        setIsEditing,
        onUpdateTemplateItem,
        isEditing,
        onChangeTemplateItem_,
    } = useBizProcUpdateAction(
        isAllowSelfChild,
        templateItem,
        onChangeTemplateItem,
        onAddTemplateItem,
        onDeleteTemplateItem,
        parentItem
    );
    const test = false;
    const isFixedAction = withFixedAction.includes(templateItem.action);

    const relation =
        !isBizProcWithRowChild &&
        isAllowSelfChild &&
        templateItem.children?.length === 0 &&
        parentItem.key !== "start"
            ? getSingleDefaultRelationRule(`self-${parentItem.key}`)
            : !bizProcLib.isBizProcWithSelfChilds(templateItem.action) &&
                bizProcLib.isBizProcWithSelfChilds(parentItem.action) &&
                !isBizProcWithRowChild &&
                parentParentItem &&
                parentItem.children?.length === index + 1 &&
                parentItem.key !== "start"
              ? getSingleDefaultRelationRule(`self-${parentParentItem.key}`)
              : getArcherRelations(
                    templateItem,
                    templateGroupItems,
                    parentNextItem,
                    customEndItemKey
                );
    return (
        <div className="template">
            <div className="template__item" onClick={editAction}>
                <ArcherElement
                    id={templateItem.key}
                    relations={getSingleDefaultRelationRule(`add-${templateItem.key}`)}
                >
                    <div style={{ width: 150 }}>
                        {test && (
                            <div>
                                <div>ID {templateItem.key}</div>
                                <div>
                                    RELATIONS
                                    {getSingleDefaultRelationRule(
                                        `add-${templateItem.key}`
                                    ).map((item) => (
                                        <div>{item.targetId}</div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <BizProcActionItem
                            disabled={disabled}
                            action={templateItem.action}
                            onDeleteItem={onDeleteTemplateItem_}
                            templateItem={templateItem}
                        />
                    </div>
                </ArcherElement>
            </div>

            {
                <ArcherElement
                    id={`add-${templateItem.key}`}
                    relations={relation}
                    gridBreak={isBizProcWithRowChild ? "50%" : undefined}
                >
                    <div>
                        {test && (
                            <div>
                                <div>ID {`add-${templateItem.key}`}</div>
                                <div>
                                    RELATIONS
                                    {relation.map((item) => (
                                        <div>{item.targetId}</div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {!isBizProcNotAllowAddAfter && (
                            <BizProcActionAddButton
                                disabled={disabled}
                                parentItem={isAllowSelfChild ? templateItem : parentItem}
                                isFixedAction={isFixedAction}
                                templateItemAction={templateItem.action}
                                onAdd={addTemplateItemAfter}
                            />
                        )}
                    </div>
                </ArcherElement>
            }

            <div
                style={{
                    display: "flex",
                    flexDirection: isBizProcWithRowChild ? "row" : "column",
                }}
            >
                {templateItem.children.map((templateItemInner, innerIndex) => {
                    return (
                        <div key={templateItemInner.key}>
                            <BizProcTemplateItem
                                disabled={disabled}
                                parentItem={templateItem}
                                index={innerIndex}
                                onAddTemplateItem={
                                    isAllowSelfChild
                                        ? onAddTemplateItemParent
                                        : onAddTemplateItem
                                }
                                onDeleteTemplateItem={
                                    isAllowSelfChild
                                        ? onDeleteTemplateItemParent
                                        : onDeleteTemplateItem_
                                }
                                onChangeTemplateItem={onChangeTemplateItem_}
                                customEndItemKey={
                                    isBizProcWithRowChild
                                        ? `self-${templateItem.key}`
                                        : customEndItemKey
                                }
                                isParentWithRowChild={
                                    isBizProcWithRowChild ? true : isParentWithRowChild
                                }
                                parentNextItem={
                                    !isBizProcWithRowChild
                                        ? templateItem.children?.[innerIndex + 1] ??
                                          parentNextItem
                                        : parentNextItem
                                }
                                parentParentItem={parentItem}
                                templateGroupItems={
                                    templateItem.children?.[0]
                                        ? templateItem.children
                                        : templateGroupItems
                                }
                                templateItem={templateItemInner}
                            />
                        </div>
                    );
                })}
            </div>

            {isAllowSelfChild &&
                isBizProcWithRowChild &&
                templateItem.children?.length > 0 && (
                    <div style={{ marginTop: 80 }}>
                        <ArcherElement
                            id={`self-${templateItem.key}`}
                            relations={getParentNextRelation(
                                parentNextItem,
                                customEndItemKey
                            )}
                            gridBreak="50%"
                        >
                            <div style={{}}>
                                <div>
                                    {test && (
                                        <div>
                                            <div>ID {`self-${templateItem.key}`}</div>
                                            <div>
                                                RELATIONS
                                                {getParentNextRelation(
                                                    parentNextItem,
                                                    customEndItemKey
                                                ).map((item) => (
                                                    <div>{item.targetId}</div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <BizProcActionAddButton
                                    disabled={disabled}
                                    parentItem={parentItem}
                                    templateItemAction={templateItem.action}
                                    onAdd={(newTemplateItem: TBizProcTemplateAction) =>
                                        onAddTemplateItem(templateItem, newTemplateItem)
                                    }
                                />
                            </div>
                        </ArcherElement>
                    </div>
                )}
            <BizProcItemEditModal
                disabled={disabled}
                isOpen={isEditing}
                bizProcTemplate={bizProcTemplate}
                setIsOpen={setIsEditing}
                templateItem={templateItem}
                onSubmit={onUpdateTemplateItem}
            />
        </div>
    );
};

export default BizProcTemplateItem;
