import { Navigate, Route, Routes } from "react-router-dom";
import { SiteLayout } from "../layout";

import { datatypeModel } from "@entities/Datatype";
import { useUserNotification } from "@features/User/UserNotification";
import { useWebsockets } from "@features/Websockets";
import { ActionsPage } from "@pages/ActionsPage";
import { AgreementPage } from "@pages/AgreementPage";
import { BizProcEditorCreatePage } from "@pages/BizProcEditorCreatePage";
import { BizProcEditorPage } from "@pages/BizProcEditorPage";
import { BizProcHistoryPage } from "@pages/BizProcInfoPage";
import { BizProcesTemplates } from "@pages/BizProcesTemplates";
import { BizProcsPage } from "@pages/BizProcsPage";
import { CalculatedValuePage } from "@pages/CalculatedValuePage";
import { CapabilitiesEditorPage } from "@pages/CapabilitiesEditorPage";
import { DashboardPage } from "@pages/DashboardPage";
import { DatatypesPage } from "@pages/DatatypesPage";
import { EntitiesPage } from "@pages/EntitiesPage";
import { EntityPage } from "@pages/EntityPage";
import { MyAgreementPage } from "@pages/MyAgreementPage";
import { MyFormsPage } from "@pages/MyFormsPage";
import { PortalSettingsPage } from "@pages/PortalSettingsPage";
import { ScannerQRCodePage } from "@pages/ScannerQRCodePage";
import { TablePageErp } from "@pages/TablePageErp";
import { UserPage } from "@pages/UserPage";
import { VariablePage } from "@pages/VariablePage";
import { DrawerManagerWidget } from "@widgets/DrawerManagerWidget";

const AuthorizedRouter = () => {
    useUserNotification();
    useWebsockets();
    datatypeModel.useLoadDatatypes();

    return (
        <>
            <SiteLayout>
                <Routes>
                    <Route path="/actions" element={<ActionsPage />} />
                    <Route
                        path="/bizproces-templates/:entityId"
                        element={<BizProcesTemplates />}
                    />
                    <Route path="/entities" element={<EntitiesPage />} />
                    <Route
                        path="/bizproc-editor/create"
                        element={<BizProcEditorCreatePage />}
                    />
                    <Route
                        path="/bizproc-editor/:bizprocId"
                        element={<BizProcEditorPage />}
                    />
                    <Route path="/user" element={<UserPage />} />
                    <Route path="/agreement" element={<AgreementPage />} />
                    <Route path="/biz-procs" element={<BizProcsPage />} />
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/my-agreement" element={<MyAgreementPage />} />
                    <Route path="/variables" element={<VariablePage />} />
                    <Route path="/entities-list" element={<EntitiesPage />} />
                    <Route path="/calculated-values" element={<CalculatedValuePage />} />
                    <Route path="/my-forms" element={<MyFormsPage />} />
                    <Route
                        path="/bizproc-dashboard/:id"
                        element={<BizProcHistoryPage />}
                    />
                    <Route path="/field-datatypes" element={<DatatypesPage />} />
                    <Route path="/qr-scanner" element={<ScannerQRCodePage />} />
                    <Route path="/portal-settings" element={<PortalSettingsPage />} />
                    <Route path="/erp/:tableName/*" element={<TablePageErp />} />
                    <Route path="/entity/:id" element={<EntityPage />} />
                    <Route
                        path="/capabilities-editor"
                        element={<CapabilitiesEditorPage />}
                    />
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="*" element={null} />
                </Routes>
            </SiteLayout>
            <DrawerManagerWidget />
        </>
    );
};

export default AuthorizedRouter;
