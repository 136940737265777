import { capabilitiesRolesModel } from "@entities/CapabilitiesRoles";
import { entityApi } from "@entities/Entity";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { entityFieldApi } from "@entities/EntityField";
import { getInitialEditingCreatingEntityRowForCapabilitiesRoles } from "../lib";

export const useCreateCapabilitiesRolesForm = (
    role: TUserRole,
    onSubmitted: () => void,
    entityCapabilitiesRolesId: number
) => {
    const dispatch = useDispatch<AppDispatch>();
    const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [newCapabilityEntityRowByRole, setNewCapabilityEntityRowByRole] =
        useState<TCapabilitiesRolesRow>(
            getInitialEditingCreatingEntityRowForCapabilitiesRoles(role)
        );
    const [entityFieldsOptions, setEntityFieldsOptions] = useState<
        { label: string; value: string }[]
    >([]);

    useEffect(() => {
        entityApi.getEntities().then((response) => {
            const entities = response.data.data;

            setOptions(
                entities.map((entity) => ({
                    label: entity.name,
                    value: entity.uuid,
                    item: entity,
                }))
            );
        });

        return () => {
            setNewCapabilityEntityRowByRole(
                getInitialEditingCreatingEntityRowForCapabilitiesRoles(role)
            );
        };
    }, []);

    const onSelectEntity = (value: string, item: any) => {
        setNewCapabilityEntityRowByRole({
            ...newCapabilityEntityRowByRole,
            entity_uuid: item.item.uuid,
        });
        entityFieldApi
            .getEntityFields(item.item.uuid, item.item.id)
            .then((response) =>
                setEntityFieldsOptions(
                    response.data.data.map((entityField) => ({
                        label: entityField.name,
                        value: entityField.key,
                    }))
                )
            )
            .catch((e) => {
                console.log(e);
                notificationEmit({
                    type: "error",
                    title: "Ошибка",
                    description: "Не удалось загрузить поля сущности",
                });
            });
    };

    const onChangeCapability = (
        value: any,
        type: TCapType,
        isMultiSelect: boolean | undefined
    ) => {
        if (newCapabilityEntityRowByRole) {
            const newValue = isMultiSelect
                ? value.length > 0
                    ? value.join(",")
                    : null
                : !!value || value === 0
                  ? value
                  : null;

            setNewCapabilityEntityRowByRole({
                ...newCapabilityEntityRowByRole,
                [type]: newValue,
            });
        }
    };

    const onSubmit = () => {
        if (newCapabilityEntityRowByRole) {
            const { id, role_id, entity_uuid, ...rest } = newCapabilityEntityRowByRole;
            const newCapabilityEntityRowRolesOnly = rest;
            setIsLoading(true);
            dispatch(
                capabilitiesRolesModel.addCapabilitiesRolesThunk({
                    id: entityCapabilitiesRolesId,
                    items: [newCapabilityEntityRowByRole],
                    newCapabilityEntityRowRolesOnly: newCapabilityEntityRowRolesOnly,
                })
            )
                .then(parseFormResponse)
                .then(() => {
                    onSubmitted();
                })
                .catch((e: any) => {
                    console.log(e);
                    notificationEmit({
                        type: "error",
                        title: "Не удалось добавить сущности",
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    return {
        options,
        isLoading,
        newCapabilityEntityRowByRole,
        entityFieldsOptions,
        onSelectEntity,
        onSubmit,
        onChangeCapability,
    };
};
