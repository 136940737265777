import { entityRowModel } from "@entities/EntityRow";
import { estimationLib } from "@entities/Estimations";
import { FormItem } from "@shared/ui/Form";
import { TagList } from "@shared/ui/List/TagList";
import { Select } from "antd";
import { useSelector } from "react-redux";

interface IEstimationTemplateEditEntityFields {
    viewMode: TEstimationFormViewMode;
    templateEntityFields: TEstimationValues;
    onChangeEstimationTemplate: (name: string, value: TEntityField[]) => void;
}

const EstimationTemplateEditEntityFields = ({
    viewMode,
    templateEntityFields,
    onChangeEstimationTemplate,
}: IEstimationTemplateEditEntityFields) => {
    const entityFields = useSelector(entityRowModel.selectEntityFields);
    const options = estimationLib.getSummableEntityFieldsOptions(entityFields);
    const choosenEntityFieldsNames = templateEntityFields.map(
        (item: TEntityField) => item?.name ?? `Поле с id: ${item.id} не найдено`
    );
    return (
        <FormItem label="Поля для суммирования">
            {viewMode === "viewing" ? (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                    }}
                >
                    {templateEntityFields.length > 0 ? (
                        <TagList
                            items={choosenEntityFieldsNames}
                            wrap={true}
                            gapHorizontal={4}
                        />
                    ) : (
                        <span>-</span>
                    )}
                </div>
            ) : (
                <Select
                    mode="multiple"
                    options={options}
                    style={{ width: "100%" }}
                    allowClear
                    value={templateEntityFields.map((item) => item.key)}
                    onChange={(value, option) =>
                        onChangeEstimationTemplate(
                            "sum_view_fields",
                            (option as { entityField: TEntityField }[]).map(
                                (option) => option.entityField
                            )
                        )
                    }
                />
            )}
        </FormItem>
    );
};

export default EstimationTemplateEditEntityFields;
