import { MoreOutlined } from "@ant-design/icons";
import { bizProcModel } from "@entities/BizProc";
import { Button, Modal, Popover, Spin } from "antd";
import { useSelector } from "react-redux";
import { useFillActionForm } from "../model/model";
import FillActionFormBuilder from "./FillActionFormBuilder";
import FillActionFormDeleteBizProc from "./FillActionFormDeleteBizProc";

// interface IFillActionForm {}

const FillActionForm = () => {
    const targetForm = useSelector(bizProcModel.selectTargetForm);
    const {
        loading,
        fields,
        loadingVariables,
        error,
        isAdmin,
        additionalButtonActions,
        onChangeFormFields,
        submitForm,
        onClose,
        handleActionsIsOpenChange,
    } = useFillActionForm(targetForm);
    const parameters = targetForm?.collected_parameters as TBizProcFormParameters;

    return (
        <Modal
            destroyOnClose
            title={parameters?.header}
            onCancel={onClose}
            maskClosable={targetForm?.action !== "InitialAction"}
            closable={targetForm?.action !== "InitialAction"}
            footer={
                targetForm?.status === "executing"
                    ? [
                          <div key="1">
                              <Popover
                                  content={
                                      <div
                                          style={{
                                              display: "flex",
                                              flexDirection: "column",
                                          }}
                                      >
                                          <Button
                                              loading={loading === "save"}
                                              onClick={() => submitForm(true)}
                                              type="link"
                                          >
                                              Сохранить как черновик
                                          </Button>
                                          {targetForm.action === "InitialAction" && (
                                              <FillActionFormDeleteBizProc
                                                  targetForm={targetForm}
                                                  onDeleted={onClose}
                                              />
                                          )}
                                      </div>
                                  }
                                  trigger="click"
                                  open={additionalButtonActions}
                                  onOpenChange={handleActionsIsOpenChange}
                              >
                                  <Button icon={<MoreOutlined />}></Button>
                              </Popover>
                              {targetForm.action === "InitialAction" ? (
                                  <FillActionFormDeleteBizProc
                                      targetForm={targetForm}
                                      triggerButtonProps={{
                                          danger: false,
                                          type: "default",
                                      }}
                                      triggerText="Закрыть"
                                      onDeleted={onClose}
                                  />
                              ) : (
                                  <Button onClick={onClose}>Закрыть</Button>
                              )}

                              <Button
                                  type="primary"
                                  loading={loading === "send"}
                                  onClick={() => submitForm()}
                              >
                                  {parameters?.button_submit_text ?? "Отправить"}
                              </Button>
                          </div>,
                      ]
                    : []
            }
            width={"90%"}
            style={{
                maxWidth: 600,
            }}
            open={targetForm !== null}
        >
            {loadingVariables ? (
                <div
                    style={{
                        height: 300,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spin />
                </div>
            ) : (
                <>
                    {targetForm && (
                        <>
                            <FillActionFormBuilder
                                ignoreUnwritable
                                allowFillAllFields={isAdmin}
                                error={error}
                                onlyRead={targetForm.status === "done"}
                                onChangeFormFields={onChangeFormFields}
                                fields={fields}
                            />
                        </>
                    )}
                </>
            )}
        </Modal>
    );
};

export default FillActionForm;
