import { entityRowModel } from "@entities/EntityRow";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getCurrentFilters,
    getEntityFieldWithFilter,
    getFiltersWithAddedValues,
    getInitialFilters,
    saveCurrentFilters,
} from "../lib";
import { useEntityRowsFilters } from "./UseEntityRowsFilters";

export const useEntityFieldsForFilters = (entityId: number) => {
    const { filters } = useEntityRowsFilters(entityId);
    const entityFields = useSelector(entityRowModel.selectEntityFields);
    const [isOpenConfiguringModal, setIsOpenConfiguringModal] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [currentFilters, setCurrentFilters] = useState<TFilterValue[]>([]);
    const currentCategory = useSelector(entityRowModel.selectCurrentCategory);

    const dispatch = useDispatch();
    const visibleInputs = getEntityFieldWithFilter(currentFilters ?? [], entityFields);

    useEffect(() => {
        let filters = currentFilters;
        const filterCategoryIndex = filters
            ? filters.findIndex((item) => item.key === "category")
            : -1;
        if (currentCategory != null) {
            filters =
                filterCategoryIndex >= 0 && filters
                    ? filters.map((item, index) => {
                          if (index === filterCategoryIndex)
                              return {
                                  ...item,
                                  value: currentCategory.toString(),
                              };
                          return item;
                      })
                    : [
                          {
                              condition: "=",
                              value: currentCategory.toString(),
                              key: "category",
                          },
                      ];
        } else {
            filters =
                filterCategoryIndex >= 0 && filters
                    ? filters.filter((item, index) => {
                          return index != filterCategoryIndex;
                      })
                    : filters;
        }
        setCurrentFilters(filters);
    }, [currentCategory]);

    useEffect(() => {
        const savedFilters = getCurrentFilters(entityId);
        setCurrentFilters(savedFilters ?? getInitialFilters(entityFields));

        // TO DO подумать над подгрузкой филдов
    }, [entityId, entityFields]);

    useEffect(() => {
        if (isVisible) {
            document.body.addEventListener("click", closeFilterInputsModal);
        }
        return () => document.body.removeEventListener("click", closeFilterInputsModal);
    }, [isVisible]);

    const openConfiguringModal = () => {
        setIsOpenConfiguringModal(true);
    };
    const closeConfiguringModal = () => {
        setIsOpenConfiguringModal(false);
    };

    const changeEntityFieldsForFilterModalVisibility = () => {
        setIsVisible(!isVisible);
    };
    const submitFilters = (currentFilters: TFilterValue[]) => {
        saveCurrentFilters(entityId, currentFilters);
        const newFilters = currentFilters.filter((item) =>
            item.condition === "null"
                ? true
                : item.value !== null && item.value !== undefined && item.value !== ""
        );
        dispatch(entityRowModel.setFilters({ filters: newFilters, entityId }));
        setIsVisible(false);
    };
    const searchByFilters = () => {
        submitFilters(currentFilters);
    };

    const setDefaultFilters = () => {
        const newFilters = getInitialFilters(entityFields);
        setCurrentFilters(getFiltersWithAddedValues(newFilters, currentFilters));
        saveCurrentFilters(entityId, newFilters);
    };
    const resetFilters = () => {
        setIsVisible(false);
        dispatch(entityRowModel.setTextFilterValue({ textFilterValue: "", entityId }));
        const newFilters = currentFilters.map((filter) => ({
            ...filter,
            value: null,
        }));
        setCurrentFilters(newFilters);
        submitFilters(newFilters);
    };

    const closeFilterInputsModal = () => {
        setIsVisible(false);
    };

    const deleteFilterInput = (event: any) => {
        const clickedFilterKey = event.currentTarget.dataset.filterKey;
        const newFilters = currentFilters.filter((item) => item.key !== clickedFilterKey);
        setCurrentFilters(newFilters);
        saveCurrentFilters(entityId, newFilters);
    };
    const onSubmitFilterFieldsModal = (filtersKeys: string[]) => {
        const newFilters = filtersKeys.map((key) => {
            const currentFilter = currentFilters.find((filter) => filter.key === key);
            if (currentFilter) return currentFilter;
            return {
                condition: "=" as TComparisonType,
                value: null,
                key,
            };
        });
        setCurrentFilters(newFilters);
        saveCurrentFilters(entityId, newFilters);
    };

    const onRemoveFiltersByKeys = (removedFiltersKeys: string[]) => {
        const newFilters = filters.filter(
            (item) => !removedFiltersKeys.includes(item.key)
        );
        dispatch(
            entityRowModel.setFilters({
                filters: newFilters,
                entityId,
            })
        );

        const newCurrentFilters = currentFilters.map((filter) => {
            return removedFiltersKeys.includes(filter.key)
                ? {
                      ...filter,
                      value: null,
                  }
                : filter;
        });
        setCurrentFilters(newCurrentFilters);
        saveCurrentFilters(entityId, newCurrentFilters);
    };
    return {
        isOpenConfiguringModal,
        isVisible,
        currentFilters,
        visibleInputs,
        filters,
        entityFields,
        setCurrentFilters,
        openConfiguringModal,
        closeConfiguringModal,
        changeEntityFieldsForFilterModalVisibility,
        searchByFilters,
        setDefaultFilters,
        resetFilters,
        closeFilterInputsModal,
        deleteFilterInput,
        onSubmitFilterFieldsModal,
        onRemoveFiltersByKeys,
    };
};
