import { SearchOutlined } from "@ant-design/icons";
import { FormInputItem } from "@entities/Datatype";
import { entityRowModel } from "@entities/EntityRow";
import { FormItem } from "@shared/ui/Form";
import { Button, Flex, Select } from "antd";
import { useSelector } from "react-redux";
import DeleteEntityFieldInput from "./DeleteEntityFieldInput";

interface IEntityRowsFiltersForm {
    isVisible: boolean;
    topCoordinateOfModal?: number;
    visibleInputs: (TBizProcFormField & {
        condition?: any;
    })[];
    setCurrentFilters: (filters: TFilterValue[]) => void;
    openConfiguringModal: () => void;
    setDefaultFilters: () => void;
    resetFilters: () => void;
    searchByFilters: () => void;
    deleteFilterInput: (event: any) => void;
}

const EntityRowsFiltersForm = ({
    isVisible,
    topCoordinateOfModal,
    visibleInputs,
    setCurrentFilters,
    openConfiguringModal,
    setDefaultFilters,
    resetFilters,
    searchByFilters,
    deleteFilterInput,
}: IEntityRowsFiltersForm) => {
    const currentCategory = useSelector(entityRowModel.selectCurrentCategory);
    const onChangeFormFieldValue = (
        newValue: any,
        inputId?: string | number | undefined
    ) => {
        const newFilters = visibleInputs.map((field) => {
            const value = field.unique_id === inputId ? newValue : field.value;

            return {
                key: field.key,
                value: value,
                condition: !field.condition && value ? "=" : field.condition ?? null,
            };
        });
        setCurrentFilters(newFilters);
    };

    const onChangeFormFieldCondition = (
        newCondition: any,
        inputId?: string | number | undefined
    ) => {
        const newFilters = visibleInputs.map((field) => {
            const condition =
                field.unique_id === inputId ? newCondition : field.condition;
            const value =
                field.unique_id === inputId
                    ? condition === "null"
                        ? ""
                        : condition === "in" ||
                            field.condition === "in" ||
                            field.condition === "between"
                          ? condition === "in"
                              ? []
                              : null
                          : field.value
                    : field.value;
            return {
                key: field.key,
                value: value,
                condition: condition,
            };
        });
        setCurrentFilters(newFilters);
    };

    return (
        <div
            className="entity-value-filter__entity-fields-inputs"
            style={{
                flexDirection: "column",
                display: isVisible ? "flex" : "none",
                top: topCoordinateOfModal ? topCoordinateOfModal + 8 : 0,
                left: 0,
            }}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            {visibleInputs.map((filterValueItem, index) => {
                return (
                    <div
                        key={index}
                        style={{
                            display: "flex",
                            alignItems: "flex-end",
                            width: "fit-content",
                            position: "relative",
                        }}
                    >
                        <FormItem
                            style={{ marginRight: 8, width: "fit-content" }}
                            label={
                                <Flex>
                                    {filterValueItem.name}
                                    <DeleteEntityFieldInput
                                        value={filterValueItem.key}
                                        action={deleteFilterInput}
                                    />
                                </Flex>
                            }
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Select
                                    onChange={(value) =>
                                        onChangeFormFieldCondition(
                                            value,
                                            filterValueItem.unique_id
                                        )
                                    }
                                    value={filterValueItem?.condition}
                                    disabled={false}
                                    placeholder="Условия сравнения"
                                    options={filterValueItem.comparison_types}
                                    size="middle"
                                    style={{
                                        boxSizing: "content-box",
                                        width: 180,
                                    }}
                                />
                            </div>
                        </FormItem>
                        <FormItem style={{ width: 240 }}>
                            <>
                                {!(
                                    filterValueItem.condition === "null" ||
                                    filterValueItem?.condition === "not_null"
                                ) && (
                                    <FormInputItem
                                        field={filterValueItem}
                                        inputId={filterValueItem.unique_id}
                                        additionalParameters={{
                                            ...(filterValueItem.additional_parameters ??
                                                {}),
                                            isInFilterInput: true,
                                            isBetween:
                                                filterValueItem?.condition === "between",
                                            isIncludeFilter:
                                                filterValueItem?.condition === "in",
                                            extraValue:
                                                filterValueItem.datatype === "category"
                                                    ? currentCategory
                                                    : undefined,
                                        }}
                                        datatype={
                                            filterValueItem.datatype as unknown as string
                                        }
                                        fieldsArray={visibleInputs}
                                        value={
                                            filterValueItem?.condition === "null" ||
                                            filterValueItem?.condition === "not_null"
                                                ? null
                                                : filterValueItem?.value
                                        }
                                        onlyRead={false}
                                        onChange={onChangeFormFieldValue}
                                    />
                                )}
                            </>
                        </FormItem>
                    </div>
                );
            })}

            <div>
                <Button
                    onClick={openConfiguringModal}
                    style={{ width: "fit-content", padding: 0 }}
                    type="link"
                >
                    Добавить поле
                </Button>
                <Button onClick={setDefaultFilters} type="link" style={{ color: "gray" }}>
                    Вернуть поля по умолчанию
                </Button>
            </div>

            <div className="entity-value-filter__entity-fields-filters-actions">
                <Button style={{ width: 100, marginRight: 8 }} onClick={resetFilters}>
                    Сбросить
                </Button>
                <Button
                    style={{ width: 100 }}
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={searchByFilters}
                >
                    Найти
                </Button>
            </div>
        </div>
    );
};

export default EntityRowsFiltersForm;
