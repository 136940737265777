import {
    capabilitiesRolesConstants,
    CapabilitiesRolesTypeMultiSelectInput,
} from "@entities/CapabilitiesRoles";
import { FormItem } from "@shared/ui/Form";
import { Button, Flex, Select, Typography } from "antd";
import { useCreateCapabilitiesRolesForm } from "../model";

interface ICreateCapabilitiesRolesForm {
    role: TUserRole;
    onSubmitted: () => void;
    onCanceled: () => void;
    extraFormItem: (
        entityRow: TCapabilitiesRolesRow,
        value: string | null,
        onChangeRow: (capType: TCapType, value: any) => void
    ) => React.ReactNode;
    entityCapabilitiesRolesId: number;
}

const CreateCapabilitiesRolesForm = ({
    extraFormItem,
    role,
    onSubmitted,
    onCanceled,
    entityCapabilitiesRolesId,
}: ICreateCapabilitiesRolesForm) => {
    const {
        options,
        isLoading,
        newCapabilityEntityRowByRole,
        entityFieldsOptions,
        onSelectEntity,
        onSubmit,
        onChangeCapability,
    } = useCreateCapabilitiesRolesForm(role, onSubmitted, entityCapabilitiesRolesId);

    return (
        <Flex vertical gap={0}>
            <Typography.Title level={5} style={{ margin: 0, padding: 0 }}>
                Добавление настроек прав для {role.name}
            </Typography.Title>
            <FormItem label="Выберите сущность">
                <Select
                    value={newCapabilityEntityRowByRole.entity_uuid}
                    style={{ width: "100%" }}
                    options={options}
                    showSearch
                    filterOption={(input: any, option) => {
                        return option?.label?.includes(input) === true;
                    }}
                    onChange={onSelectEntity}
                />
            </FormItem>
            {capabilitiesRolesConstants.capabilitiesTypes.map((capabilityType, index) => {
                return capabilityType.key !== "constraints" ? (
                    <FormItem label={capabilityType.label} key={index}>
                        {capabilityType.isMultiSelect ? (
                            <CapabilitiesRolesTypeMultiSelectInput
                                isFocused={true}
                                additionalOptions={entityFieldsOptions}
                                bordered={false}
                                value={
                                    newCapabilityEntityRowByRole[
                                        capabilityType.key
                                    ] as string
                                }
                                onChange={(value) =>
                                    onChangeCapability(
                                        value,
                                        capabilityType.key,
                                        capabilityType.isMultiSelect
                                    )
                                }
                                isDefaultOpen={false}
                                isLoading={isLoading}
                            />
                        ) : (
                            <Select
                                style={{ width: "100%" }}
                                options={capabilitiesRolesConstants.options}
                                bordered={true}
                                value={newCapabilityEntityRowByRole[capabilityType.key]}
                                loading={isLoading}
                                onChange={(value) =>
                                    onChangeCapability(
                                        value,
                                        capabilityType.key,
                                        capabilityType.isMultiSelect
                                    )
                                }
                            />
                        )}
                    </FormItem>
                ) : (
                    <FormItem label="Ограничения">
                        <>
                            {extraFormItem(
                                newCapabilityEntityRowByRole,
                                newCapabilityEntityRowByRole.constraints,
                                (capType, value) =>
                                    onChangeCapability(value, capType, false)
                            )}
                        </>
                    </FormItem>
                );
            })}
            <Flex justify="end">
                <Button disabled={isLoading} loading={isLoading} onClick={onCanceled}>
                    Отмена
                </Button>
                <Button
                    style={{ marginLeft: 8 }}
                    onClick={onSubmit}
                    disabled={isLoading}
                    loading={isLoading}
                    type="primary"
                >
                    Сохранить
                </Button>
            </Flex>
        </Flex>
    );
};

export default CreateCapabilitiesRolesForm;
