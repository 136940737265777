import { FormInputItem } from "@entities/Datatype";
import { entityRowModel } from "@entities/EntityRow";
import { useSelector } from "react-redux";
import { getEntityFieldWithFilter } from "../lib";
import CurrentActiveFiltersValueDeleteIcon from "./CurrentActiveFiltersValueDeleteIcon";
import { PlusOutlined } from "@ant-design/icons";
import { theme } from "antd";
import { conditionPreffix } from "../constants";

interface ICurrentActiveFiltersValues {
    onRemoveFiltersByKeys: (removedFiltersKeys: string[]) => void;
}

const CurrentActiveFiltersValues = ({
    onRemoveFiltersByKeys,
}: ICurrentActiveFiltersValues) => {
    const entityFields = useSelector(entityRowModel.selectEntityFields);
    const filters = useSelector(entityRowModel.selectFilters);
    const mainTheme = theme.useToken();
    const activeFiltersValues = getEntityFieldWithFilter(
        filters ?? [],
        entityFields
    ).filter((item) => item.key !== "category");

    return (
        <div style={{ display: "flex" }}>
            {activeFiltersValues.slice(0, 3).map((value, index) => {
                return (
                    <div
                        className="entity-value-filter__saved-filter"
                        style={{ backgroundColor: mainTheme.token.colorPrimary }}
                        key={index}
                    >
                        <CurrentActiveFiltersValueDeleteIcon
                            action={onRemoveFiltersByKeys}
                            isFilledIcon={true}
                            value={value.key}
                        />
                        <div className="entity-value-filter__saved-filter-item">
                            <span style={{ marginRight: 6, textWrap: "nowrap" }}>
                                {`${value.name}: ${value?.condition ? conditionPreffix[value?.condition] ?? "" : ""}`}
                            </span>
                            {value?.condition === "null" ? (
                                <span>Незаполнено</span>
                            ) : value?.condition === "not_null" ? (
                                <span>Заполнено</span>
                            ) : (
                                <FormInputItem
                                    value={value.value}
                                    field={value}
                                    additionalParameters={{
                                        isBetween: value?.condition === "between",
                                    }}
                                    onlyRead={true}
                                    fieldsArray={activeFiltersValues}
                                    datatype={value.datatype}
                                    onChange={() => {}}
                                />
                            )}
                        </div>
                    </div>
                );
            })}
            {activeFiltersValues.length > 3 && (
                <div
                    className="entity-value-filter__saved-filter"
                    style={{ background: "var(--primary)" }}
                >
                    <CurrentActiveFiltersValueDeleteIcon
                        action={onRemoveFiltersByKeys}
                        isFilledIcon={true}
                        value={activeFiltersValues.slice(3).map((field) => field.key)}
                    />
                    <div className="entity-value-filter__saved-filter-item">
                        <span>Еще: {activeFiltersValues.length - 3}</span>
                    </div>
                </div>
            )}
            {activeFiltersValues.length > 0 && (
                <PlusOutlined
                    style={{
                        fontSize: 10,
                    }}
                />
            )}
        </div>
    );
};

export default CurrentActiveFiltersValues;
