import { bizProcModel } from "@entities/BizProc";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useBizProcListActions = (bizProc: TBizProc) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const onConfirmDelete = () => {
        setLoading(true);
        const index = bizProc.id;
        if (index) {
            dispatch(bizProcModel.deleteBizProcThunk(index))
                .then(parseFormResponse)
                .then(() => {
                    navigate("/biz-procs");
                    notificationEmit({
                        title: "Удаление бизнес-процесса",
                        description: `Бизнес-процесс "${bizProc.name}" удалён`,
                        type: "info",
                    });
                })
                .catch((e: any) =>
                    notificationEmit({
                        error: e,
                        type: "error",
                        title: "Ошибка",
                    })
                );
        }
    };

    return {
        onConfirmDelete,
        loading,
    };
};
