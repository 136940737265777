import { MenuOutlined } from "@ant-design/icons";
import { entityRowModel } from "@entities/EntityRow";
import { EntityRowTableActions } from "@features/CreateEditEntityRow";
import { EntityRowsKanban } from "@features/Entity/EntityRowsKanban";
import { EntityRowsScheduler } from "@features/Entity/EntityRowsScheduler";
import { EntityRowsTable } from "@features/Entity/EntityRowsTable";
import { EntityRowsActions } from "@features/EntityRow/EntityRowsActions";
import { Tabs } from "antd";
import { useSelector } from "react-redux";
import "./EntityRowsView.scss";

interface IEntityRowsView {
    entity: TEntity;
    tabMode: string;
    onChangeTabMode: (tabMode: string) => void;
    openCloseDrawerWithNavigate: (
        isOpenDrawer: boolean,
        entityRowId: number | undefined | null
    ) => void;
}

const EntityRowsView = ({
    entity,
    tabMode,
    onChangeTabMode,
    openCloseDrawerWithNavigate,
}: IEntityRowsView) => {
    const capabilities = useSelector(entityRowModel.selectEntityUserCapabilities);

    const items = [
        {
            key: "table",
            label: "Таблица",
            children: (
                <EntityRowsTable
                    entityId={entity.id}
                    openCloseDrawerWithNavigate={openCloseDrawerWithNavigate}
                    rowsActions={
                        capabilities?.update === 9
                            ? (selectedRows, onSelectRows, levelKey) => (
                                  <EntityRowsActions
                                      onSelectRows={onSelectRows}
                                      selectedRows={selectedRows}
                                      entityId={entity.id}
                                      levelKey={levelKey}
                                  />
                              )
                            : undefined
                    }
                    actions={(entityRow, levelKey) => (
                        <EntityRowTableActions
                            entity={entity}
                            levelKey={levelKey}
                            entityRow={entityRow}
                            type={"link"}
                            icon={<MenuOutlined />}
                            onlyIcon={true}
                            label="Действия"
                            isDropdown={true}
                            openCloseDrawerWithNavigate={openCloseDrawerWithNavigate}
                        />
                    )}
                />
            ),
        },
        {
            key: "scheduler",
            label: "Календарь",
            children: (
                <EntityRowsScheduler
                    entityUuid={entity.uuid}
                    entityId={entity.id}
                    openCloseDrawerWithNavigate={openCloseDrawerWithNavigate}
                />
            ),
        },
        {
            key: "kanban",
            label: "Канбан",
            children: (
                <EntityRowsKanban
                    entityUuid={entity.uuid}
                    entityId={entity.id}
                    openCloseDrawerWithNavigate={openCloseDrawerWithNavigate}
                />
            ),
        },
    ];

    return (
        <Tabs
            className="entity-rows-format-tabs"
            destroyInactiveTabPane
            activeKey={tabMode}
            items={items}
            onChange={onChangeTabMode}
        />
    );
};

export default EntityRowsView;
