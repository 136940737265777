import { viewPatternItemModel } from "@entities/ViewPatternItem";
import { useDispatch } from "react-redux";
import ViewPatternItemCreateBizProcButton from "./ViewPatternItemCreateBizProcButton";
import ViewPatternItemCreateChildBizProcButton from "./ViewPatternItemCreateChildBizProcButton";
import ViewPatternItemCreateGroup from "./ViewPatternItemCreateGroup";
import { Flex } from "antd";
import { FormItem } from "@shared/ui/Form";
import { UserAccess } from "@entities/User";

interface IViewPatternCreatingSelectsGroup {
    viewPattern: TViewPattern;
    viewPatternItems: TViewPatternItem[];
    entityFields: TEntityField[];
    setCreateLoading: React.Dispatch<
        React.SetStateAction<{
            [key: string]: boolean;
            [key: number]: boolean;
        }>
    >;
}

const ViewPatternCreatingSelectsGroup = ({
    viewPattern,
    viewPatternItems,
    entityFields,
    setCreateLoading,
}: IViewPatternCreatingSelectsGroup) => {
    const dispatch = useDispatch<AppDispatch>();

    const onCreate = (
        label: string,
        type: TViewPatternItemType,
        type_value: string | number | null
    ) => {
        const newViewPatternItem: TViewPatternItemCreating = {
            label,
            type,
            parent_id: null,
            view_pattern_id: viewPattern.id,
            type_value,
            order: viewPatternItems.filter((item) => item.parent_id === null).length,
        };
        if (type_value) {
            setCreateLoading(
                (createLoading: { [key: string]: boolean; [key: number]: boolean }) => ({
                    ...createLoading,
                    [type_value]: true,
                })
            );
        }

        dispatch(
            viewPatternItemModel.createViewPatternItemThunk(newViewPatternItem)
        ).then(() => {
            if (type_value) {
                setCreateLoading((createLoading) => ({
                    ...createLoading,
                    [type_value]: false,
                }));
            }
        });
    };

    return (
        <Flex
            gap="0 8px"
            align="top"
            wrap="wrap"
            style={{
                flex: "1 1 59%",
                padding: 10,
                margin: 0,
                height: "fit-content",
                borderRadius: 8,
                backgroundColor:"rgba(0, 0, 0, 0.05)",
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 2px",
            }}
        >
            <UserAccess.AdminAccess>
            <FormItem
                style={{ flex: "1 1 100%", marginBottom: 8 }}
                label="Запуск бизнес-процесса"
            >
                <ViewPatternItemCreateBizProcButton
                    onCreate={onCreate}
                    entityId={viewPattern.entity_id}
                />
            </FormItem>
            <FormItem
                style={{ flex: "1 1 100%", marginBottom: 8 }}
                label="Запуск бизнес-процесса для связанного поля"
            >
                <ViewPatternItemCreateChildBizProcButton
                    onCreate={onCreate}
                    entityFields={entityFields ?? []}
                />
            </FormItem>
            </UserAccess.AdminAccess>
            <FormItem label="Имя группы" style={{ flex: "1 1 auto", marginBottom: 8 }}>
                <ViewPatternItemCreateGroup onCreate={onCreate} />
            </FormItem>
        </Flex>
    );
};

export default ViewPatternCreatingSelectsGroup;
