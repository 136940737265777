import { bizProcTemplateApi } from "@entities/BizProcTemplate";
import { ExportButton } from "@features/Action/Export";
import { BizProcTemplateItemList } from "@features/BizProcTemplate/BizProcTemplateItemList";
import { useEffect, useState } from "react";

interface IBizProcTemplatesByCategory {
    categoryUuid: string;
    entityUuid: string;
}

const BizProcTemplatesByCategory = ({
    entityUuid,
    categoryUuid,
}: IBizProcTemplatesByCategory) => {
    const [bizProcTemplates, setBizProcTempaltes] = useState<TBizProcTemplateItem[]>([]);

    const onDeleted = (id: number) => {
        setBizProcTempaltes((templates) => templates.filter((item) => item.id !== id));
    };

    useEffect(() => {
        bizProcTemplateApi
            .getBizProcCategoryTemplates(entityUuid, categoryUuid)
            .then((response) => {
                setBizProcTempaltes(response.data.data);
            })
            .catch(() => {})
            .finally(() => {});
    }, []);

    return (
        <BizProcTemplateItemList
            bizProcTemplates={bizProcTemplates}
            onDeleted={onDeleted}
            bottomActions={(bizProcTemplate) => (
                <ExportButton
                    url={`bizproc-template/${bizProcTemplate.id}/export`}
                    filename={bizProcTemplate.name}
                />
            )}
        />
    );
};

export default BizProcTemplatesByCategory;
