import { entityFieldModel } from "@entities/EntityField";
import { useDispatch, useSelector } from "react-redux";

export const useEntityFieldUpdatingModal = () => {
    const dispatch = useDispatch();
    const editingEntityField = useSelector(entityFieldModel.selectEditingEntityField);

    const onClose = () => {
        dispatch(entityFieldModel.setEditingEntityField(null));
    };

    return {
        onClose,
        editingEntityField,
    };
};
