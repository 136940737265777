import { bizProcTemplateApi } from "@entities/BizProcTemplate";
import { useDebounceEffect } from "@shared/hooks";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { ChangeEvent, useRef, useState } from "react";
import {
    getIsSimpleVariable,
    getStringInputVariable,
    getValueWithInjectedKey,
} from "../lib";
export const useBizProcTemplateInputVariables = (
    itemId: number | string,
    type: "entity" | "biz_proc",
    name: string,
    value: string | undefined,
    isTextArea: boolean,
    onChange: (name: string, value: string) => void
) => {
    const [itemsGroups, setItemsGroups] = useState<TDropdownVariablesGroup[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [calculatedValueType, setCalculatedValueType] = useState<string | null>(null);
    const [selectedRelatedVariable, setSelectedRelatedVariable] =
        useState<TDropdownItem | null>(null);
    const [selectMode, setSelectMode] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const inputRef = useRef<any>();

    const checkSelectState = (value: string) => {
        let newSelectModeState = false;
        const isVariableMode = value.indexOf("={");
        if (isVariableMode >= 0) {
            newSelectModeState = true;
            if (!selectMode) {
                setIsLoading(true);
            }
            const splitedValue = value.split("={");
            const searchValue = splitedValue.splice(1).join("").split(" ")[0];
            setSearchValue(searchValue);
        } else {
            setSearchValue("");
            setSelectedRelatedVariable(null);
            setCalculatedValueType(null);
        }

        setSelectMode(newSelectModeState);
        return newSelectModeState;
    };

    const onChangeInput = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = event.target.value.replace("={", "={").replace("}", "}");
        const name = event.target.name;
        checkSelectState(value);
        onChange(name, value);
    };

    const onSelectItem = (item: TDropdownItem) => {
        const isSimpleVariable = getIsSimpleVariable(item);
        setCalculatedValueType(
            item.variable_type === "calculated_value" ? item.type : null
        );
        const inputString = getStringInputVariable(
            item,
            isSimpleVariable,
            selectedRelatedVariable,
            calculatedValueType
        );
        const newValue = getValueWithInjectedKey(
            value ?? "",
            inputString,
            isSimpleVariable,
            selectedRelatedVariable,
            calculatedValueType
        );
        onChange(name, newValue);

        if (isSimpleVariable) {
            setSelectMode(false);
            setSelectedRelatedVariable(null);
        } else {
            setSelectedRelatedVariable(item);
        }
        setSearchValue("");

        inputRef.current.focus();
        if (!isSimpleVariable) {
            setSelectionOnSearchInput(newValue);
        }
    };
    const setSelectionOnSearchInput = (newValue: string) => {
        const position = newValue.indexOf("={") + 2;
        setTimeout(() => {
            try {
                if (isTextArea)
                    inputRef.current.resizableTextArea?.textArea?.setSelectionRange(
                        position,
                        position
                    );
                else inputRef.current.input?.setSelectionRange(position, position);
            } catch {}
        });
    };

    const getBizProcTemplateVariables = (params?: TGetBizProcTemplateVariablesParams) => {
        setIsLoading(true);
        (type === "biz_proc"
            ? bizProcTemplateApi.getBizProcTemplateVariables(itemId as number, params)
            : bizProcTemplateApi.getEntityVariables(itemId as string, params)
        )
            .then((response) => {
                setItemsGroups(response.data.data);
            })
            .catch((error) => {
                notificationEmit({
                    error: error,
                    title: error.message ?? "Ошибка",
                    type: "error",
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useDebounceEffect(
        () => {
            if (selectMode) {
                getBizProcTemplateVariables({
                    searchValue: searchValue,
                    calculatedValueType: calculatedValueType,
                    variableId: selectedRelatedVariable?.id,
                    variableType: selectedRelatedVariable?.variable_type,
                });
            }
        },
        () => {
            setItemsGroups([]);
        },
        [
            itemId,
            selectMode,
            calculatedValueType,
            searchValue,
            selectedRelatedVariable?.id,
            selectedRelatedVariable?.variable_type,
        ]
    );

    return {
        isLoading,
        itemsGroups,
        inputRef,
        selectMode,
        onChangeInput,
        getBizProcTemplateVariables,
        onSelectItem,
    };
};
