import { bizProcTemplateApi } from "@entities/BizProcTemplate";
import { entityModel } from "@entities/Entity";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useState } from "react";

export const useEntityRowObserversList = (entityUuid: string) => {
    const [loadingTemplates, setLoadingTemplates] = useState(true);
    const [bizProcTemplates, setBizProcTemplates] = useState<TBizProcTemplateItem[]>([]);
    const { entityRowObservers, loading } =
        entityModel.useEntityRowsObservers(entityUuid);
    const entityRowObserversWithName = entityRowObservers.map((entityRowObserver) => {
        const bizProcTemplate = bizProcTemplates.find(
            (bizProcTemplate) =>
                bizProcTemplate.uuid === entityRowObserver.biz_proc_template_uuid
        );
        return {
            ...entityRowObserver,
            bizProcName: bizProcTemplate?.name ?? "Не найден",
        };
    });
    const loadEntityBizProcTemplates = () => {
        setLoadingTemplates(true);
        bizProcTemplateApi
            .getBizProcEntityTemplatesByUuid(entityUuid)
            .then((response) => {
                setBizProcTemplates(response.data.data);
            })
            .catch((error) => {
                notificationEmit({
                    error: error,
                    title: error.message ?? "Ошибка загрузки шаблонов",
                    type: "error",
                });
            })
            .finally(() => {
                setLoadingTemplates(false);
            });
    };
    useEffect(() => {
        loadEntityBizProcTemplates();
    }, []);

    const entityRowObserversByCreate = entityRowObserversWithName.filter(
        (item) => item.type === "on_create"
    );
    const entityRowObserversByUpdate = entityRowObserversWithName.filter(
        (item) => item.type === "on_update"
    );
    return {
        loading: loadingTemplates || loading,
        bizProcTemplates,
        entityRowObserversByCreate: entityRowObserversByCreate,
        entityRowObserversByUpdate: entityRowObserversByUpdate,
        entityRowObservers,
    };
};
