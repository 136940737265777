import { bizProcApi } from "@entities/BizProc";
import { bizProcTemplateModel } from "@entities/BizProcTemplate";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const useBottomActions = (
    bizProcTemplateItem: TBizProcTemplateItem,
    updateBizProcTemplate?: (bizProcTemplateItem: TBizProcTemplateItem) => void,
    onStart?: (bizProc: TBizProcTemplateItem) => void
) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const onStart_ = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        if (!bizProcTemplateItem.id) return;
        setLoading(true);
        bizProcApi
            .startBizProc(bizProcTemplateItem.id)
            .then((response) => {
                const bizProcTemplateItem = response.data.data;
                if (updateBizProcTemplate) {
                    updateBizProcTemplate(bizProcTemplateItem);
                } else {
                    dispatch(
                        bizProcTemplateModel.updateBizProcEntityTemplateItem(
                            bizProcTemplateItem
                        )
                    );
                }
                onStart && onStart(bizProcTemplateItem);
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        onStart_,
        loading,
    };
};
