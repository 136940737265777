import { EntitySkeletonItem } from "@entities/Entity";
import "./EntitiesList.scss";

const EntitySkeletonItemsList = () => {
    const skeletonCounts = Number(localStorage.getItem("entitiesLength"));
    const multiply = Array.from({ length: skeletonCounts > 0 ? skeletonCounts : 1 });

    return (
        <div className="entities-list__skeletons">
            {multiply.map((_, index) => {
                return (
                    <div className="entities-list__item--skeleton" key={index}>
                        <EntitySkeletonItem />
                    </div>
                );
            })}
        </div>
    );
};

export default EntitySkeletonItemsList;
