import { Statistic } from "antd";
import React from "react";
import "./AgreementAdditionalInfo.scss";
import { getNormalizedTime } from "@shared/lib/date";
import { UserDateWithTimezone } from "@entities/User";

interface IAgreementAdditionalInfo {
    agreement: TBizProcAction | null;
    children?: React.ReactNode;
}

const AgreementAdditionalInfo = ({ agreement, children }: IAgreementAdditionalInfo) => {
    const { Countdown } = Statistic;

    const deadlineDate = new Date(agreement ? agreement.deadline_at : 0);

    return (
        <div className="agreement-additional">
            <div className="agreement-additional__paragraph">{children}</div>
            <div className="agreement-additional__paragraph">
                {agreement?.status && agreement.status === "approve" && (
                    <UserDateWithTimezone
                        label="Согласовано: "
                        date={agreement.updated_at}
                    />
                )}
                {agreement?.status && agreement.status === "reject" && (
                    <UserDateWithTimezone
                        label="Отказано: "
                        date={agreement.updated_at}
                    />
                )}
            </div>
            {agreement?.deadline_at &&
                agreement?.status &&
                agreement?.status === "executing" && (
                    <>
                        <div className="agreement-additional__paragraph">
                            <UserDateWithTimezone
                                label="Согласовать до: "
                                date={agreement.deadline_at}
                            />
                        </div>
                        <div className="agreement-additional__paragraph">
                            {agreement.deadline_status !== "expired" && (
                                <>
                                    <span>Срок согласования истекает через:</span>
                                    <Countdown
                                        value={deadlineDate.getTime()}
                                        valueStyle={{ fontSize: 14, color: "gray" }}
                                    />
                                </>
                            )}
                        </div>
                        <div className="agreement-additional__paragraph agreement-additional__paragraph-expired">
                            {agreement.deadline_status === "expired" && (
                                <span className="expired">Срок согласования истёк</span>
                            )}
                        </div>
                    </>
                )}
        </div>
    );
};

export default AgreementAdditionalInfo;
