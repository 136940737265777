import { bizProcApi, bizProcModel } from "@entities/BizProc";
import { bizProcTemplateApi, bizProcTemplateModel } from "@entities/BizProcTemplate";
import { userModel } from "@entities/User";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

export const useBizProcTemplateStartMenu = (
    bizProcTemplateIdQueryParam: string | null
) => {
    const [dashboardItems, setDashboardItems] = useState<TUserTemplate[]>([]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<number | null>(null);
    const user = useSelector(userModel.selectUser);
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        bizProcTemplateApi
            .getBizProcUserTemplates()
            .then((response) => {
                setDashboardItems(response.data.data);
            })
            .catch(() => {})
            .finally(() => {});
    }, []);

    useEffect(() => {
        bizProcTemplateIdQueryParam && onStart(+bizProcTemplateIdQueryParam);
    }, [bizProcTemplateIdQueryParam]);

    const onStart = (bizProcTemplateId: number) => {
        if (!bizProcTemplateId) return;
        setLoading(bizProcTemplateId);
        bizProcApi
            .startBizProc(bizProcTemplateId)
            .then((response) => {
                const bizProcTemplateItem = response.data.data;
                dispatch(
                    bizProcTemplateModel.updateBizProcEntityTemplateItem(
                        bizProcTemplateItem
                    )
                );
                if (
                    !bizProcTemplateItem.start_biz_proc_action ||
                    !bizProcTemplateItem.start_biz_proc_action.collected_parameters
                        ?.users_ids
                )
                    return;
                const action = bizProcTemplateItem.start_biz_proc_action;
                const usersIds =
                    bizProcTemplateItem.start_biz_proc_action.collected_parameters
                        ?.users_ids;

                if (
                    ((action.action === "InitialAction" &&
                        action.parameters?.triggerType === "form") ||
                        action.action === "FormAction") &&
                    usersIds.includes(user.id)
                )
                    dispatch(bizProcModel.setTargetForm(action));
            })
            .catch(() => {})
            .finally(() => {
                setLoading(null);
                if (bizProcTemplateIdQueryParam) {
                    searchParams.delete("start-biz-proc-template-id");
                    setSearchParams(searchParams);
                }
            });
    };

    return {
        onStart,
        loading,
        dashboardItems,
    };
};
