import { variableModel } from "@entities/Variables";
import { VariableCreateButton } from "@features/Variables/CreateUpdateVariables";
import { EmptyPage } from "@shared/EmptyPage";
import { CardError } from "@shared/ui/Cards/CardError";
import { useSelector } from "react-redux";
import VariableNormalItem from "./VariableNormalItem";
import "./VariablesList.scss";
import VariablesSkeletonList from "./VariablesSkeletonList";

const VariablesList = () => {
    const variables = useSelector(variableModel.selectVariables);
    const isLoading = useSelector(variableModel.selectIsLoading);
    const error = useSelector(variableModel.selectError);

    if (error) {
        const { message } = error?.error;
        const { status } = error?.payload;
        return <CardError message={message} status={status} />;
    }
    if (variables.length > 0) {
        return (
            <>
                <div className="variables-list">
                    {variables.map((variable) => {
                        return (
                            <div key={variable.id} className="variables-list__item">
                                <VariableNormalItem variable={variable} />
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }
    if (isLoading && variables.length < 1) {
        return <VariablesSkeletonList />;
    }
    return (
        <EmptyPage
            text="Переменные не найдены"
            button={<VariableCreateButton isEmptyPage={true} />}
        />
    );
};

export default VariablesList;
