import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Upload, UploadFile } from "antd";
import React, { memo } from "react";
import { useFileUpload } from "./model";

interface IFileUpload {
    uploadUrl: string;
    maxFiles?: number;
    beforeUpload?: (file: any) => void;
    initialFileList: UploadFile[];
    onlyRead?: boolean;
    smallFormat?: boolean;
    onChangeFileList: (fileList: any) => void;
}

const FileUpload = ({
    uploadUrl,
    maxFiles,
    smallFormat,
    initialFileList,
    beforeUpload,
    onChangeFileList,
    onlyRead,
}: IFileUpload) => {
    const {
        uploadFile,
        handleChange,
        handleCancel,
        handlePreview,
        onRemove,
        previewOpen,
        previewImage,
        previewTitle,
        accessToken,
        fileList,
    } = useFileUpload({
        uploadUrl,
        initialFileList,
        onChangeFileList,
    });

    return (
        <>
            {onlyRead && fileList.length === 0 ? (
                <></>
            ) : (
                <Upload
                    listType={smallFormat ? "text" : "picture-card"}
                    customRequest={uploadFile}
                    defaultFileList={initialFileList}
                    onRemove={onRemove}
                    fileList={fileList}
                    beforeUpload={beforeUpload}
                    headers={{
                        authorization: `Bearer ${accessToken}`,
                    }}
                    showUploadList={{
                        showRemoveIcon: !onlyRead,
                        showDownloadIcon: true,
                    }}
                    itemRender={(
                        originNode: React.ReactElement,
                        file: UploadFile,
                        fileList: Array<UploadFile>,
                        actions: {
                            download: () => void;
                            preview: () => void;
                            remove: () => void;
                        }
                    ) => {
                        if (!onlyRead) return originNode;

                        if (smallFormat)
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        height: "100%",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        cursor: "pointer",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        // handlePreview(file);
                                    }}
                                >
                                    <>
                                        <span
                                            className="link-style"
                                            style={{
                                                marginRight: 10,

                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                textWrap: "nowrap",
                                            }}
                                            onClick={actions.preview}
                                        >
                                            {file.name}
                                        </span>
                                        <Button
                                            type="text"
                                            size="small"
                                            style={{
                                                padding: 3,
                                                color: "#919090",
                                                margin: 0,
                                                minHeight: 20,
                                            }}
                                        >
                                            <DownloadOutlined
                                                onClick={actions.download}
                                            />
                                        </Button>
                                    </>
                                </div>
                            );
                        return (
                            <div
                                style={{
                                    height: "100%",
                                    cursor: "pointer",
                                    width: "100%",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // handlePreview(file);
                                }}
                            >
                                {originNode}
                            </div>
                        );
                    }}
                    multiple
                    openFileDialogOnClick
                    onPreview={handlePreview}
                    onChange={handleChange}
                >
                    {onlyRead || fileList.length >= (maxFiles ?? 10) ? null : (
                        <button style={{ border: 0, background: "none" }} type="button">
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Добавить</div>
                        </button>
                    )}
                </Upload>
            )}
            {onlyRead === true && fileList.length === 0 && (
                <span>Файлы не добавлены</span>
            )}
            <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
                style={{ minWidth: "fit-content" }}
            >
                <div
                    className="image-container"
                    style={{ width: "100%", textAlign: "center" }}
                >
                    <img
                        alt="example"
                        src={previewImage}
                        style={{ aspectRatio: "auto", maxHeight: "70vh" }}
                    />
                </div>
            </Modal>
        </>
    );
};

export default memo(FileUpload, (prevProps, nextProps) => {
    const prevFileList = prevProps.initialFileList;
    const nextFileList = nextProps.initialFileList;
    if (prevFileList.length === nextFileList.length) {
        for (let index = 0; index < prevFileList.length; index++) {
            if (prevFileList[index].url !== nextFileList[index].url) {
                return false;
            }
        }
    }
    return false;
});
