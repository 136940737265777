import { UsersAssygnment } from "@entities/BizProc";
import { FillActionFormBuilder } from "@features/BizProc/FillActionForm";
import { ConfirmCard } from "@shared/ui/Cards/ConfirmCard";
import { Divider, Modal, Spin, Tag } from "antd";
import { useApproveAgreement } from "../model";
import { AgreementAdditionalInfo } from "./AgreementAdditionalInfo";
import "./ApproveAgreement.scss";

const ApproveAgreement = () => {
    const {
        agreement,
        loading,
        canApprove,
        parameters,
        error,
        loadingVariables,
        fields,
        onChange,
        onCancel,
        onChangeFormFields,
        onSubmit,
        onClose,
    } = useApproveAgreement();

    const windowWidth = window.innerWidth;
    return (
        <>
            <Modal
                destroyOnClose
                onCancel={onClose}
                width={windowWidth > 475 ? 600 : "90%"}
                open={agreement !== null}
                footer={[]}
                title={
                    <div className="approve-modal-title">
                        {(agreement?.isMy || agreement?.status !== "executing") && (
                            <Tag
                                style={{ fontWeight: 600 }}
                                color={`${
                                    agreement?.status === "approve"
                                        ? "green"
                                        : agreement?.status === "reject"
                                          ? "red"
                                          : "processing"
                                }`}
                            >
                                {`${
                                    agreement?.status === "approve"
                                        ? "Согласовано"
                                        : agreement?.status === "reject"
                                          ? "Отказ"
                                          : "Ожидает согласования"
                                }`}
                            </Tag>
                        )}
                    </div>
                }
            >
                <ConfirmCard
                    title={<>{parameters?.header}</>}
                    body={parameters?.body}
                    description={
                        <>
                            <AgreementAdditionalInfo agreement={agreement}>
                                {agreement && (
                                    <UsersAssygnment
                                        users={agreement.collected_parameters.users}
                                        status={agreement.status}
                                    />
                                )}
                            </AgreementAdditionalInfo>
                            {agreement?.collected_parameters?.fields?.length && (
                                <>
                                    <Divider>Дополнительная информация</Divider>
                                    {loadingVariables ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: 200,
                                            }}
                                        >
                                            <Spin />
                                        </div>
                                    ) : (
                                        <FillActionFormBuilder
                                            ignoreUnwritable
                                            error={error}
                                            onlyRead={agreement?.status === "done"}
                                            onChangeFormFields={onChangeFormFields}
                                            fields={fields}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    }
                    comment={parameters?.comment}
                    cancelLoading={loading === "reject"}
                    submitLoading={loading === "approve"}
                    statusTabAgreement={
                        (agreement?.isMy || agreement?.status !== "executing") && (
                            <Tag
                                style={{ fontWeight: 600 }}
                                color={`${
                                    agreement?.status === "approve"
                                        ? "green"
                                        : agreement?.status === "reject"
                                          ? "red"
                                          : "processing"
                                }`}
                            >
                                {`${
                                    agreement?.status === "approve"
                                        ? "Согласовано"
                                        : agreement?.status === "reject"
                                          ? "Отказ"
                                          : "Ожидает согласования"
                                }`}
                            </Tag>
                        )
                    }
                    onCancel={canApprove ? onCancel : undefined}
                    onSubmit={canApprove ? onSubmit : undefined}
                    onChange={onChange}
                />
            </Modal>
        </>
    );
};

export default ApproveAgreement;
