import { CloseOutlined } from "@ant-design/icons";
import "./EntityNameBookmark.scss";
import { Tooltip } from "antd";
interface IEntityNameBookmark {
    entity: TEntity;
    zIndex?: number;
    top?: number;
    onClose?: () => void;
}

const EntityNameBookmark = ({ entity, zIndex, top, onClose }: IEntityNameBookmark) => {
    return (
        <div className="bookmark" style={{ zIndex, top }} onClick={onClose}>
            {onClose && (
                <span className="bookmark__close link-style" onClick={onClose}>
                    <CloseOutlined />
                </span>
            )}
            <Tooltip title={entity.name}>{entity.name}</Tooltip>
        </div>
    );
};

export default EntityNameBookmark;
