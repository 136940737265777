import {
    ERP_LOCATION_PATTERN,
    LOCATION_ENTITY_ROW_ID_INDEX,
    LOCATION_ENTITY_TABLE_INDEX,
} from "./constants";

export const parseErpLocationData = (windowLocation: string) => {
    const index = windowLocation.search(ERP_LOCATION_PATTERN);
    if (index === -1) return null;
    const erpLocation = windowLocation.slice(index);
    const splittedLocation = erpLocation.split("/").filter((item) => item !== "");
    const entityRowId = Number(splittedLocation[LOCATION_ENTITY_ROW_ID_INDEX]);
    const tableName = splittedLocation[LOCATION_ENTITY_TABLE_INDEX];

    return {
        entityRowId: Number.isNaN(entityRowId) ? null : Number(entityRowId),
        entityTableName: !tableName ? null : tableName,
        location: erpLocation,
    };
};

export const getDrawerManagerItem = (
    windowLocation: string
): TDrawerManagerItemPrepared | null => {
    const queryIndex = windowLocation.indexOf("?");
    const query = queryIndex !== -1 ? windowLocation.slice(queryIndex + 1) : "";
    const clearWindowLocation = windowLocation.replace(query, "");

    const erpLocationData = parseErpLocationData(clearWindowLocation);
    if (
        !erpLocationData ||
        !erpLocationData.entityRowId ||
        !erpLocationData.entityTableName
    )
        return null;

    return {
        location: erpLocationData.location,
        rowId: erpLocationData?.entityRowId,
        entityTableName: erpLocationData?.entityTableName,
        isOpen: true,
    };
};

export const parseOriginalLocation = (windowLocation: string) => {
    const queryIndex = windowLocation.indexOf("?");
    const query = queryIndex !== -1 ? windowLocation.slice(queryIndex + 1) : "";
    const clearWindowLocation = windowLocation.replace(query, "");
    const erpLocationData = parseErpLocationData(clearWindowLocation);
    if (!erpLocationData || !erpLocationData.entityRowId) return windowLocation;

    return `${erpLocationData?.location.replace(`/${erpLocationData.entityRowId}`, "")}${query}`;
};

export const isHigherItem = () => {};
export const getItemLevel = () => {};
export const generateLocation = (item: TDrawerManagerItem) => {
    return `${ERP_LOCATION_PATTERN}/${item.entityTableName}/${item.rowId ?? "create"}`;
};
