import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { saveKanbanConfigurationToLocalStorage } from "../lib";
import { entityFieldApi } from "@entities/EntityField";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";

export const useEntityRowsKanbanConfigure = (
    entityUuid: string,
    setChoosenEntityFieldId: (item: number | null) => void,
    setChoosenVisibleFieldsOptionsKeys: (item: string[]) => void
) => {
    const [entityFields, setEntityFields] = useState<TEntityField[]>([]);
    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
    const options = entityFields.map((entityField) => ({
        label: entityField.name,
        value: entityField.key,
        item: entityField,
    }));

    useEffect(() => {
        entityFieldApi
            .getEntityFields(entityUuid)
            .then((response) => {
                setEntityFields(response.data.data);
            })
            .catch(() => {
                notificationEmit({
                    title: "Не удалось загрузить поля",
                    type: "error",
                });
            });
    }, []);

    const groupOptions = options.filter(({ item }) => {
        if (
            [
                "date",
                "datetime",
                "entity",
                "timestamp",
                "integer",
                "editor",
                "longText",
                "entityList",
                "float",
                "file",
            ].includes(item.datatype) ||
            item.is_multiple
        )
            return false;
        return true;
    });
    const onSelectFilteringField = (value: string | undefined) => {
        if (value) {
            const choosenEntityField = entityFields.find((field) => field.key === value)!;
            setChoosenEntityFieldId(choosenEntityField.id!);
            saveKanbanConfigurationToLocalStorage(
                entityUuid,
                "filteringEntityField",
                choosenEntityField.id!
            );
        } else {
            setChoosenEntityFieldId(null);
            saveKanbanConfigurationToLocalStorage(
                entityUuid,
                "filteringEntityField",
                null
            );
        }
    };
    const onSelectVisibleFields = (value: string[]) => {
        if (isArray(value)) {
            saveKanbanConfigurationToLocalStorage(
                entityUuid,
                "visibleEntityFields",
                value
            );
            setChoosenVisibleFieldsOptionsKeys(value);
        } else {
            setChoosenVisibleFieldsOptionsKeys([]);
            saveKanbanConfigurationToLocalStorage(
                entityUuid,
                "visibleEntityFields",
                null
            );
        }
    };

    const openDrawer = () => setIsOpenDrawer(true);
    const closeDrawer = () => setIsOpenDrawer(false);
    return {
        isOpenDrawer,
        options,
        groupOptions,
        onSelectFilteringField,
        onSelectVisibleFields,
        openDrawer,
        closeDrawer,
    };
};
