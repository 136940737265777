import { userModel } from "@entities/User";
import { emitter } from "@shared/emitter";
import { useSelector } from "react-redux";

export const useTablePageErpLoaded = (entity: TEntity) => {
    const userId = useSelector(userModel.selectUserId);
    const openCloseDrawerWithNavigate = (
        isOpenDrawer: boolean,
        entityRowId?: number | null,
        initialValues?: any
    ) => {
        emitter.emit("drawer-manager-emit", {
            rowId: entityRowId,
            entityTableName: entity?.table_name,
            initialValues,
        });
    };

    return {
        userId,
        openCloseDrawerWithNavigate,
    };
};
