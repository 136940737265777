import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { FormItem } from "@shared/ui/Form";
import CommonCreateEntityRowForm from "../CommonCreateEntityRowForm";
import { EntitySelect } from "@entities/Entity";

interface IEditCreateEntityRowAction {
    bizProcTemplate: TBizProcTemplateItem;
    templateItem: TBizProcTemplateAction;
    disabled?: boolean;
    setItem: (templateItem: TBizProcTemplateAction) => void;
}
const EditCreateEntityRowAction = ({
    templateItem,
    bizProcTemplate,
    disabled,
    setItem,
}: IEditCreateEntityRowAction) => {
    const selectedEntityUuid = templateItem.parameters?.entity_uuid ?? null;

    const onChangeEntity = (
        value: string | null,
        item: { label: string; value: string; item: TEntity } | null
    ) => {
        setItem({
            ...templateItem,
            parameters: item
                ? {
                      entity_uuid: item.value,
                      values: null,
                  }
                : {},
        });
    };
    return (
        <div>
            <IndentContainer>
                <FormItem label="Выберите сущность">
                    <EntitySelect
                        multiple={false}
                        disabled={disabled}
                        value={selectedEntityUuid}
                        onChangeSelect={onChangeEntity}
                    />
                </FormItem>
            </IndentContainer>
            {selectedEntityUuid && (
                <CommonCreateEntityRowForm
                    entityUuid={selectedEntityUuid}
                    setItem={setItem}
                    disabled={disabled}
                    templateItem={templateItem}
                    bizProcTemplateId={bizProcTemplate.id!}
                />
            )}
        </div>
    );
};

export default EditCreateEntityRowAction;
