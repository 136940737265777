import { hiddenTimelineItemActions } from "../../../features/BizProc/BizProcInfo/constants";

export const getVisibleTimelineItems = (actions: TBizProcAction[]) => {
    return actions
        .map((action: TBizProcAction): TBizProcAction | undefined => {
            if (hiddenTimelineItemActions.includes(action.action)) {
                return undefined;
            }
            return action;
        })
        .filter((item): item is TBizProcAction => item !== undefined);
};
