import { entityModel } from "@entities/Entity";
import { UserAccess } from "@entities/User";
import { ImportButton } from "@features/Action/Import";
import { EntitiesList } from "@features/Entity/EntitiesList";
import { EntityCreateButton } from "@features/Entity/EntityCreateButton";
import { GenerateQRCode, GenerateQRCodeButton } from "@features/GenerateQRCode";
import { EmptyPage } from "@shared/EmptyPage";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { useHeaderLayout } from "@shared/ui/Header";
import { EntityModifyModal } from "@widgets/Entity/CreateUpdateEntity";
import { EntityFilters } from "@widgets/Filters/EntityFilters";
import { Space, Typography } from "antd";
import "./EntitiesPage.scss";
import { useSelector } from "react-redux";
import { CardError } from "@shared/ui/Cards/CardError";
const EntitiesPage = () => {
    useHeaderLayout({
        title: "Сущности",
    });
    const { entities, isLoading } = entityModel.useLoadEntities(true);
    const referenceEntities = entities.filter((entity) => entity.is_reference);
    const guidebookEntities = entities.filter((entity) => entity.is_guidebook);
    const mainEntities = entities.filter(
        (entity) => !entity.is_reference && !entity.is_guidebook
    );
    const error = useSelector(entityModel.selectError);

    return (
        <div>
            <UserAccess.AdminAccess>
                <IndentContainer>
                    <Space>
                        {entities.length > 0 && <EntityCreateButton />}
                        <ImportButton />
                    </Space>
                </IndentContainer>
            </UserAccess.AdminAccess>
            <EntityFilters />
            <EntityModifyModal />
            {mainEntities.length === 0 &&
                referenceEntities.length === 0 &&
                !isLoading &&
                !error && (
                    <EmptyPage
                        text="Сущности не найдены"
                        button={
                            <UserAccess.AdminAccess>
                                <EntityCreateButton isEmptyPage={true} />
                            </UserAccess.AdminAccess>
                        }
                    />
                )}
            {!isLoading && error && (
                <CardError message={error?.message} status={error?.status} />
            )}
            {mainEntities.length > 0 && !error && (
                <IndentContainer>
                    <EntitiesList
                        entities={mainEntities}
                        additionalActions={(entity: TEntity) => (
                            <>
                                <UserAccess.AdminAccess>
                                    <GenerateQRCodeButton entity={entity} />
                                </UserAccess.AdminAccess>
                            </>
                        )}
                    />
                </IndentContainer>
            )}
            {referenceEntities.length > 0 && !error && (
                <>
                    <Typography.Title level={4}>
                        Вспомогательные сущности
                    </Typography.Title>
                    <EntitiesList
                        entities={referenceEntities}
                        additionalActions={(entity: TEntity) => (
                            <>
                                <UserAccess.AdminAccess>
                                    <GenerateQRCodeButton entity={entity} />
                                </UserAccess.AdminAccess>
                            </>
                        )}
                    />
                </>
            )}
            {guidebookEntities.length > 0 && !error && (
                <>
                    <Typography.Title level={4}>Справочные сущности</Typography.Title>
                    <EntitiesList
                        entities={guidebookEntities}
                        additionalActions={(entity: TEntity) => (
                            <>
                                <UserAccess.AdminAccess>
                                    <GenerateQRCodeButton entity={entity} />
                                </UserAccess.AdminAccess>
                            </>
                        )}
                    />
                </>
            )}
            <GenerateQRCode />
        </div>
    );
};

export default EntitiesPage;
