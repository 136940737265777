import { Button } from "antd";
import { useToggleEditingMode } from "./model";
import { UserAccess } from "@entities/User";
import { CloseOutlined } from "@ant-design/icons";

interface IToggleNavigationEditingMode {}

const ToggleNavigationEditingModeButton = ({}: IToggleNavigationEditingMode) => {
    const { editingNavigationMode, onToggle } = useToggleEditingMode();
    return (
        <UserAccess.AdminAccess>
            <Button
                style={{
                    marginLeft: 10,
                    marginTop: 20,
                }}
                type="link"
                danger={editingNavigationMode}
                onClick={onToggle}
                icon={editingNavigationMode ? <CloseOutlined /> : undefined}
            >
                {editingNavigationMode ? "Выйти из настройки" : "Настройка меню"}
            </Button>
        </UserAccess.AdminAccess>
    );
};

export default ToggleNavigationEditingModeButton;
