import { bizProcApi } from "@entities/BizProc";
import { useEffect, useState } from "react";

export const useFormFields = (
    bizProcId: number | undefined,
    formFields: TBizProcFormField[] | undefined
) => {
    const [loadingVariables, setLoadingVariables] = useState(false);
    const [fields, setFields] = useState<TBizProcFormField[]>([]);

    useEffect(() => {
        if (bizProcId && formFields) {
            setLoadingVariables(true);
            setFields([]);
            bizProcApi
                .getBizProcVariablesValues(bizProcId, formFields)
                .then((response) => {
                    setFields(response.data.data);
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    setLoadingVariables(false);
                });
        }
    }, [bizProcId]);

    return {
        loadingVariables,
        setLoadingVariables,
        fields,
        setFields,
    };
};
