import { bizProcModel } from "@entities/BizProc";
import { checkRequiredFields } from "@entities/Datatype";
import { userModel } from "@entities/User";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useFillActionForm = (
    targetForm: TBizProcAction<TBizProcFormParameters> | null
) => {
    const dispatch = useDispatch<AppDispatch>();
    const [error, setError] = useState<any>({});
    const [additionalButtonActions, setAdditionalButtonActions] = useState(false);
    const isAdmin = useSelector(userModel.selectUserIsAdmin);
    const [loading, setLoading] = useState<null | "save" | "send">(null);
    const { loadingVariables, fields, setFields } = bizProcModel.useFormFields(
        targetForm?.biz_proc_id,
        targetForm?.collected_parameters?.fields
    );

    const onChangeFormFields = (fields: TBizProcFormField[], id?: string) => {
        if (id)
            setError({
                ...error,
                [id]: false,
            });
        if (targetForm) {
            setFields(fields);
        }
    };

    const submitForm = (onlySave?: boolean) => {
        if (targetForm) {
            const { isValid, errorFields } = onlySave
                ? {
                      isValid: true,
                      errorFields: {},
                  }
                : checkRequiredFields(fields);

            setError(errorFields);
            if (!isValid) return;
            setLoading(onlySave ? "save" : "send");
            dispatch(
                bizProcModel.submitBizProcFormThunk({
                    formId: targetForm?.id,
                    fields: fields ?? [],
                    onlySave: onlySave,
                })
            )
                .then((response: any) => {
                    if (response.error) {
                        notificationEmit({
                            title: "Не удалось сохранить форму",
                            type: "error",
                        });
                    } else {
                        onClose();
                    }
                })

                .finally(() => {
                    setLoading(null);
                });
        }
    };
    const onClose = () => {
        dispatch(bizProcModel.setTargetForm(null));
        setFields([]);
        setError([]);
        setAdditionalButtonActions(false);
    };
    const hideActions = () => {
        setAdditionalButtonActions(false);
    };

    const handleActionsIsOpenChange = (newOpen: boolean) => {
        setAdditionalButtonActions(newOpen);
    };
    return {
        loading,
        fields,
        targetForm,
        loadingVariables,
        error,
        additionalButtonActions,
        isAdmin,
        onChangeFormFields,
        submitForm,
        onClose,
        handleActionsIsOpenChange,
        hideActions,
    };
};
