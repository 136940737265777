import { EntityFieldUpdatingForm } from "@features/Entity/EntityFieldUpdatingForm";
import { FieldOptionEditor } from "@features/FieldOption/FieldOptionEditor";
import { DatatypeDependingInputs } from "@features/FieldsTypes/DatatypeDependingInputs";
import { Modal } from "antd";
import { useEntityFieldUpdatingModal } from "./model";

const EntityFieldUpdatingModal = () => {
    const { editingEntityField, onClose } = useEntityFieldUpdatingModal();

    return (
        <>
            <Modal
                title={`${editingEntityField?.id ? "Создание" : "Редактирование"} поля`}
                open={editingEntityField !== null}
                onCancel={onClose}
                centered
                footer={[]}
                destroyOnClose
            >
                {editingEntityField && (
                    <EntityFieldUpdatingForm
                        datatypeDependingInputs={(props) => (
                            <DatatypeDependingInputs
                                editingField={props.editingField as TEntityField}
                                setEditingField={
                                    props.setEditingField as (
                                        entityField: TEntityField | TVariable
                                    ) => void
                                }
                                error={props.error}
                                optionEditor={(
                                    fieldId: number,
                                    type: TFieldOptionType
                                ) => <FieldOptionEditor fieldId={fieldId} type={type} />}
                            />
                        )}
                        onSuccessSave={onClose}
                        onCancel={onClose}
                        initialEntityField={editingEntityField}
                    />
                )}
            </Modal>
        </>
    );
};

export default EntityFieldUpdatingModal;
