import { bizProcModel } from "@entities/BizProc";
import { userModel } from "@entities/User";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export const useBizProcsWS = () => {
    const isAdmin = useSelector(userModel.selectUserIsAdmin);
    const dispatch = useDispatch();
    const user = useSelector(userModel.selectUser);
    useEffect(() => {
        window.Echo.channel(isAdmin ? `BizProcs` : `BizProcs.${user.id}`)
            .listen(".bizProcs.update", ({ bizProc }: { bizProc: TBizProc }) => {
                dispatch(bizProcModel.updateBizProc(bizProc));
            })
            .listen(".bizProcs.create", ({ bizProc }: { bizProc: TBizProc }) => {
                dispatch(bizProcModel.addBizProc(bizProc));
            })
            .listen(".bizProcs.delete", ({ bizProc }: { bizProc: TBizProc }) => {
                dispatch(bizProcModel.deleteBizProc(bizProc));
            });
        return () => {
            window.Echo.leave(isAdmin ? `BizProcs` : `BizProcs.${user.id}`);
        };
    }, [isAdmin]);
};
