import { ReactNode } from "react";
import "./CardWithHead.scss";
import { Divider } from "antd";
interface ICardWithHead {
    title?: string | ReactNode;
    noColor?: boolean;
    withDivider?: boolean;
    bordered?: boolean;
    backgroundColor?: string;
    borderColor?: string;
    childrenBackgroundColor?: string;
    children?: ReactNode;
    titleClassName?: string;
    width?: string | number;
    childrenClassName?: string;
}

const CardWithHead = ({
    children,
    withDivider,
    width,
    backgroundColor,
    childrenBackgroundColor,
    noColor,
    title,
    bordered,
    titleClassName,
    borderColor,
    childrenClassName,
}: ICardWithHead) => {
    return (
        <div
            style={{ width: width, borderColor: borderColor ? borderColor : "#1890ff" }}
            className={`card-with-head ${bordered ? "bordered" : ""}`}
        >
            <div
                className={`card-with-head__title ${noColor ? "" : "colored"} ${titleClassName}`}
                style={{ backgroundColor: backgroundColor }}
            >
                {title}
            </div>
            {withDivider && <Divider style={{ margin: 0 }} />}
            {children && (
                <div
                    className={`card-with-head__body ${childrenClassName}`}
                    style={{ backgroundColor: childrenBackgroundColor }}
                >
                    {children}
                </div>
            )}
        </div>
    );
};

export default CardWithHead;
