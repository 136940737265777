import { $api } from "@shared/api/api";
const route = "/api/entity";

export const getEntities = (params?: TParams) => {
    return $api.get<TResponseList<TEntity>>(route, { params: params });
};
export const getEntity = (entityId: number) => {
    return $api.get<TResponse<TEntity>>(`${route}/${entityId}`);
};
export const getEntityByTableName = (tableName: string) => {
    return $api.get<TResponse<TEntity>>(`${route}/by-name/${tableName}`);
};
export const getDepartments = (params?: TParams) => {
    return $api.get<TResponseList<TDepartment>>("/api/items/departments", {
        params: params,
    });
};

export const createEntity = (entity: TEntity) => {
    return $api.post<TResponse<TEntity>>(route, entity);
};
export const updateEntity = (entity: TEntity) => {
    return $api.put<TResponse<TEntity>>(`${route}/${entity.id}`, entity);
};
export const deleteEntity = (id: number) => {
    return $api.delete<TResponse<TEntity>>(`${route}/${id}`);
};
export const getEntityValuesList = (
    id: number,
    params?: TParams & { specialConditionKey?: string }
) => {
    return $api.post<TResponse<any>>(`${route}/${id}/values`, params);
};
export const getEntityValuesListPaginated = (
    id: number,
    page: number,
    perPage: number
) => {
    return $api.get<TResponse<any>>(`${route}/${id}/values`, {
        params: { page, per_page: perPage },
    });
};
export const saveEntityRowItem = (id: number, item: any) => {
    return $api.post<TResponse<any>>(`${route}/${id}/values/add`, {
        data: [item],
    });
};

export const getHistoryOfEntityRow = (entityId: number, entityRowId: number) =>
    $api.get<TResponse<TBizProcAction[]>>(
        `${route}/${entityId}/row/${entityRowId}/actions`
    );
export const getHistoryOfEntityRowByTableName = (
    entityTableName: string,
    entityRowId: number
) =>
    $api.get<TResponse<TBizProcAction[]>>(
        `${route}/by-name/${entityTableName}/row/${entityRowId}/actions`
    );

export const startChildBizProc = (
    entityId: number,
    entityRowId: number,
    field: string,
    bizProcTemplateId: number
) => {
    return $api.post(
        `${route}/${entityId}/row/${entityRowId}/child-bizproc/${field}/start/${bizProcTemplateId}`
    );
};

export const getEntityFieldsForCreateByCapabilities = (entityId: number) =>
    $api.get<TResponse<TEntityField[]>>(`${route}/${entityId}/fields/access/create`);

export const getEntityFieldsForUpdateByCapabilities = (
    entityId: number,
    entityRowId: number
) =>
    $api.get<TResponseList<TEntityField>>(
        `${route}/${entityId}/fields/access/update/${entityRowId}`
    );
export const getEntityFieldsForReadByCapabilities = (
    entityId: number,
    entityRowId: number
) =>
    $api.get<TResponseList<TEntityField>>(
        `${route}/${entityId}/fields/access/read/${entityRowId}`
    );
