import { useState } from "react";
import { getViewModeForTemplate } from "../lib";
import { useSelector } from "react-redux";
import { userModel } from "@entities/User";
import { estimationLib } from "@entities/Estimations";

export const useEstimationTemplateEditForm = (
    initialEstimationTemplate: TCreatingEstimationTemplate | TEstimationTemplate | null
) => {
    const user = useSelector(userModel.selectUser);
    const [estimationTemplate, setEstimationTemplate] = useState(
        initialEstimationTemplate ??
            estimationLib.getCreatingEstimationTemplate("", user.id)
    );

    const viewMode = getViewModeForTemplate(initialEstimationTemplate, user);

    const onChangeEstimationTemplate = (name: string, value: any) => {
        setEstimationTemplate({
            ...estimationTemplate,
            [name]: value,
        });
    };

    return {
        estimationTemplate,
        viewMode,
        onChangeEstimationTemplate,
    };
};
