import { Fragment } from "react";
import { useDrawerManagerWidget } from "../model";
import { CreateEditEntityRowDrawer } from "@widgets/CreateEditEntityRowDrawer";
import { BizProcHistoryListWithLoad } from "@widgets/BizProcWidgets/BizProcHistory";

// interface IDrawerManagerWidget {}

const DrawerManagerWidget = () => {
    const { openedDrawers, closeDrawer } = useDrawerManagerWidget();

    return (
        <>
            {openedDrawers.map((drawerItem, index, fullArr) => {
                return (
                    <Fragment key={drawerItem.location}>
                        <CreateEditEntityRowDrawer
                            level={
                                fullArr.filter((item) => item.isOpen).length - 1 - index
                            }
                            entityTableName={drawerItem.entityTableName}
                            isOpenDrawer={drawerItem.isOpen}
                            entityRowId={drawerItem.rowId}
                            hideHistory={false}
                            history={(props) => <BizProcHistoryListWithLoad {...props} />}
                            initialValues={drawerItem.initialValues}
                            openCloseDrawerWithNavigate={() => closeDrawer(drawerItem)}
                        />
                    </Fragment>
                );
            })}
        </>
    );
};

export default DrawerManagerWidget;
