import { entityFieldApi } from "@entities/EntityField";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useState } from "react";

export const useCapabilitiesRolesConstraintsForm = (
    entityRow: TCapabilitiesRolesRow,
    onChangeRow: (capType: TCapType, value: any) => void,
    additionalAction?: () => void,
    isCreating?: boolean
) => {
    const [constraints, setConstraints] = useState<TMultipleCondition>(
        entityRow.constraints
            ? JSON.parse(entityRow.constraints)
            : {
                  condition_type: "AND",
                  conditions: [],
              }
    );
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [entityFields, setEntityFields] = useState<TEntityField[]>([]);
    const entityFieldsOptions = entityFields.map((field) => ({
        label: field.name,
        value: field.key,
        option: field,
    }));

    useEffect(() => {
        if (entityRow.entity_uuid) {
            setIsLoading(true);
            entityFieldApi
                .getEntityFields(entityRow.entity_uuid!)
                .then((response) => {
                    setEntityFields(response.data.data);
                })
                .catch((e) => {
                    notificationEmit({
                        type: "error",
                        title: "Ошибка",
                        description: "Ошибка загрузки полей сущности",
                    });
                    console.log(e);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [entityRow]);

    const onChangeConstraints = (newConstraints: any) => {
        isCreating &&
            onChangeRow(
                "constraints",
                newConstraints.conditions.length === 0
                    ? null
                    : JSON.stringify(newConstraints)
            );
        setConstraints(newConstraints);
    };

    const onChangeConstraintsItem = (newCondition: any, indexOfNewCondition: number) => {
        onChangeConstraints({
            ...constraints,
            conditions: constraints.conditions.map(
                (oldCondition: any, indexOfOldCondition: any) =>
                    indexOfOldCondition === indexOfNewCondition
                        ? newCondition
                        : oldCondition
            ),
        });
    };

    const onDeleteConstraintsItem = (newConditions: any[]) => {
        onChangeConstraints({
            ...constraints,
            conditions: newConditions,
        });
    };

    const onAddCondition = (type: "single" | "multiple") => {
        onChangeConstraints({
            ...constraints,
            conditions: [
                ...constraints.conditions,
                type === "single"
                    ? { key: undefined, value: undefined, condition: undefined }
                    : { condition_type: "AND", conditions: [] },
            ],
        });
    };

    const onSubmit = () => {
        onChangeRow(
            "constraints",
            constraints.conditions.length === 0 ? null : JSON.stringify(constraints)
        );
        additionalAction && additionalAction();
    };

    const onCancel = () => {
        additionalAction && additionalAction();
    };

    const onChangeGroupCondition = (value: string) => {
        onChangeConstraints({ ...constraints, condition_type: value });
    };

    return {
        isLoading,
        constraints,
        entityFieldsOptions,
        onAddCondition,
        onChangeConstraintsItem,
        onDeleteConstraintsItem,
        onSubmit,
        onCancel,
        onChangeGroupCondition,
    };
};
