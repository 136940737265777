import { bizProcModel } from "@entities/BizProc";
import { userModel } from "@entities/User";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const UseBizProcCountersWS = () => {
    const user = useSelector(userModel.selectUser);
    const dispatch = useDispatch();

    useEffect(() => {
        window.Echo.channel(`User.${user.id}.Counters`)
            .listen(".forms", ({ count }: { count: number }) => {
                dispatch(bizProcModel.setFormsCounter(count));
            })
            .listen(".agreements", ({ count }: { count: number }) => {
                dispatch(bizProcModel.setAgreementsCounter(count));
            });

        return () => {
            window.Echo.leave(`User.${user.id}.Counters`);
        };
    }, []);
};
